<template>
  <span/>
</template>

<script>
import { ITINERARY_ACTION_UPDATE_POI, INFORMATION_STORE_NAME, ACTION_AUTO_FOCUS_POI, INFORMATION_GET_SELECTED_ITEM_GETTER, INFORMATION_ACTION_SET_SELECTED_ITEM, PLAYER_PLAY_MODE_GETTER, PLAYER_IS_PLAYING_GETTER, PLAYER_STORE_NAME, PLAYER_STEP_GETTER, VIDEO_PLAY_MODE } from '@/store'
import * as Cesium from 'cesium/Cesium'
import arrowDownIcon from '@/assets/icons/arrow_down.png'
import { mapGetters } from 'vuex'
import { viewerInstance as viewer } from '@/store/modules/viewer.js'

const defaultArrowScale = 0.7
const selectedArrowScale = 2
const defaultArrowColor = Cesium.Color.WHITE
const selectedArrowColor = Cesium.Color.DEEPPINK
const defaultArrowOffsetHeight = new Cesium.Cartesian2(0, -20)
const selectedArrowOffsetHeight = new Cesium.Cartesian2(0, -25)
const defaultLabelColor = Cesium.Color.WHITE
const selectedLabelColor = Cesium.Color.DEEPPINK
const defaultLabelFont = '22px Helvetica'
const selectedLabelFont = '30px Helvetica'
const depthTestDistance = 3000

const setStyles = (label, arrow, selectedEntity) => {
  let arrowScale = defaultArrowScale; let arrowColor = defaultArrowColor; let labelColor = defaultLabelColor; let labelFont = defaultLabelFont; let arrowHeightOffset = defaultArrowOffsetHeight

  if (selectedEntity) {
    arrowScale = selectedArrowScale
    arrowColor = selectedArrowColor
    labelColor = selectedLabelColor
    labelFont = selectedLabelFont
    arrowHeightOffset = selectedArrowOffsetHeight
  }
  arrow.billboard.scale = arrowScale
  arrow.billboard.color = arrowColor
  arrow.billboard.outlineColor = Cesium.Color.WHITE
  arrow.billboard.outlineWidth = 5
  arrow.billboard.pixelOffset = arrowHeightOffset
  label.label.fillColor = labelColor
  label.label.font = labelFont
}

export default {
  props: ['poiData'],
  data () {
    return {
      billboards: [],
      label: null,
      arrowDown: null,
      viewed: false // Cette variable d'état permet de savoir si le poi a été visualisé en mode automatique. se réinitialise à false lorsque mise en pause ou changement de mode
    }
  },
  mounted () {
    this.label = viewer.entities.add({
      position: this.poiData.cartesianCoordinates,
      label: {
        show: this.poiData.canRenderLabel(),
        text: this.poiData.label,
        fillColor: Cesium.Color.WHITE,
        outlineColor: Cesium.Color.BLACK,
        outlineWidth: 2,
        font: '22px Helvetica',
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        disableDepthTestDistance: depthTestDistance,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE
      },
      billboard: {
        show: this.poiData.image,
        image: this.poiData.image,
        scale: 0.4,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        disableDepthTestDistance: depthTestDistance
      }
    })

    this.poiData.setCesiumEntity(this.label)
    this.$store.dispatch(ITINERARY_ACTION_UPDATE_POI, this.poiData)

    this.arrowDown = viewer.entities.add({
      position: this.poiData.cartesianCoordinates,
      billboard: {
        image: arrowDownIcon,
        scale: defaultArrowScale,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        heightReference: Cesium.HeightReference.NONE,
        disableDepthTestDistance: depthTestDistance
      }
    })

    this.billboards.push(this.arrowDown)
    const isItineraryStartOrEnd = this.poiData.isItineraryStart || this.poiData.isItineraryEnd

    let nbFacilities = this.poiData.facilities?.length || 0
    if (isItineraryStartOrEnd) nbFacilities -= 1
    const verticalOffsetStepIcon = -17
    const heightLabel = -25

    const offsetYLabel = heightLabel + verticalOffsetStepIcon * (nbFacilities + 1)
    const offsetLabel = new Cesium.Cartesian2(0, offsetYLabel)
    this.label.label.pixelOffset = offsetLabel
    this.label.billboard.pixelOffset = offsetLabel
    // Afficher les installations associées à la zone
    let bottomPosition = 0
    for (let index = 0; index < this.poiData.facilities?.length; index++) {
      const facility = this.poiData.facilities[index]
      const facilityStartOrEnd = facility.isStart || facility.isEnd
      const scale = facilityStartOrEnd ? 0.5 : 0.25
      const facilityEntity = viewer.entities.add({
        position: this.poiData.cartesianCoordinates,
        billboard: {
          image: facility.imageUrl,
          scale: scale,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          heightReference: Cesium.HeightReference.NONE,
          disableDepthTestDistance: depthTestDistance
        }
      })
      let pixelOffset
      if (facilityStartOrEnd) {
        pixelOffset = new Cesium.Cartesian2(0, offsetYLabel + 2 * verticalOffsetStepIcon)
      } else {
        pixelOffset = new Cesium.Cartesian2(0, heightLabel + verticalOffsetStepIcon * (bottomPosition + 1))
        bottomPosition += 1
      }
      facilityEntity.billboard.pixelOffset = pixelOffset
      this.billboards.push(facilityEntity)
    }

    // Créer l'handler de sélection de POI dans la vue
    const scene = viewer.scene
    const handler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
    handler.setInputAction((movement) => {
      var pickedObject = scene.pick(movement.position)
      if (Cesium.defined(pickedObject) && this.hasMatchingEntity(pickedObject.id)) {
        this.$store.dispatch(INFORMATION_ACTION_SET_SELECTED_ITEM, this.poiData)
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    setStyles(this.label, this.arrowDown, false)
  },
  destroyed () {
    viewer.entities.remove(this.label)
    viewer.entities.remove(this.arrowDown)
    this.billboards.forEach(billboard => {
      viewer.entities.remove(billboard)
    })
  },
  methods: {
    hasMatchingEntity (pickedEntityId) {
      const pickedArrow = pickedEntityId === this.arrowDown
      const pickedLabel = pickedEntityId === this.label
      const pickedBillboard = this.billboards.findIndex(billboard => pickedEntityId === billboard) > -1
      return pickedArrow || pickedLabel || pickedBillboard
    },
    reinitializeAutofocus () {
      this.viewed = this.step >= this.poiData.itineraryIndex + 5
    }
  },
  computed: {
    ...mapGetters(INFORMATION_STORE_NAME, {
      selectedItemMetadata: INFORMATION_GET_SELECTED_ITEM_GETTER
    }),
    ...mapGetters(PLAYER_STORE_NAME, {
      step: PLAYER_STEP_GETTER,
      playing: PLAYER_IS_PLAYING_GETTER,
      playMode: PLAYER_PLAY_MODE_GETTER
    })
  },
  watch: {
    async step (value) {
      if (!this.playing) {
        this.reinitializeAutofocus()
      }
      if (this.poiData.focusOnVideo && this.playing && !this.viewed && this.poiData.itineraryIndex <= value && this.playMode === VIDEO_PLAY_MODE) {
        this.viewed = true
        await this.$store.dispatch(ACTION_AUTO_FOCUS_POI, this.poiData)
      }
    },
    playMode (value, oldValue) {
      if (oldValue === VIDEO_PLAY_MODE && value !== oldValue) {
        this.reinitializeAutofocus()
      }
    },
    playing (value) {
      if (!value && this.playMode !== VIDEO_PLAY_MODE) {
        this.reinitializeAutofocus()
      }
    },
    selectedItemMetadata (item, oldValue) {
      if (item !== oldValue) {
        // Mettre en évidence le poi
        setStyles(this.label, this.arrowDown, item === this.poiData)
      }
    }
  },
  beforeDestroy () {

  }
}
</script>

<style>

</style>
