import { ACCESS_ACTION_SET_SELECTED, ACCESS_ACTION_UNSELECT_ACCESS } from '@/store'

export const INFORMATION_STORE_NAME = 'informations'
export const INFORMATION_GET_SELECTED_ITEM_GETTER = 'getSelectedItem'
export const INFORMATION_ACTION_SET_SELECTED_ITEM = `${INFORMATION_STORE_NAME}/setSelectedItem`
export const INFORMATION_ACTION_UNSELECTED_ITEM = `${INFORMATION_STORE_NAME}/unSelectItem`

const state = () => ({
  selectedItemMetadata: null
})

const getters = {
  getSelectedItem: (state) => {
    return state.selectedItemMetadata
  }
}

const mutations = {
  setSelectedItem (state, itemMetadata) {
    state.selectedItemMetadata = itemMetadata
  }
}

const actions = {
  setSelectedItem ({ commit }, itemMetadata) {
    commit('setSelectedItem', itemMetadata)
    commit(ACCESS_ACTION_SET_SELECTED, [itemMetadata.getAccessKey()], { root: true })
  },
  unSelectItem ({ commit, dispatch }) {
    commit('setSelectedItem', null)
    dispatch(ACCESS_ACTION_UNSELECT_ACCESS, null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
