<template>
  <div v-bind:style="style" class="meta-timeline">
    <template v-for='(cover, index) in covers'>
      <Cover :key="index" :coverData="cover"/>
    </template>
    <template v-for='poi in pois'>
      <POI v-if="poi.type === FEED_TECH_TYPE" :key='poi.key' :poi="poi"/>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ITINERARY_POIS_GETTER, ITINERARY_GETTER_COVERS, ITINERARY_STORE_NAME } from '@/store'
import { renderPx } from '@/Utils'
import { FEED_TECH_TYPE } from '@/Classes/POI.js'
import POI from './POI.vue'
import Cover from './Cover'

export default {
  data () {
    return {
      FEED_TECH_TYPE: FEED_TECH_TYPE
    }
  },
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      pois: ITINERARY_POIS_GETTER,
      covers: ITINERARY_GETTER_COVERS
    }),
    style () {
      return {
        marginLeft: renderPx(this.borderMargins.left)
      }
    }
  },
  props: ['borderMargins'],
  components: { POI: POI, Cover: Cover }
}
</script>

<style>
.meta-timeline {
  bottom: 40px;
  position: absolute
}
</style>
