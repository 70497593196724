export const AccessTransportTypes = {
  CAR: 'CAR',
  BIKE: 'BIKE'
}

export const imagesTransportTypes = {
  CAR: require('@/assets/icons/car.png'),
  BIKE: require('@/assets/icons/bike.png')
}

export default class Access {
  constructor (accessGeojson) {
    const accessFeatures = accessGeojson.features[0]
    const accessProperties = accessFeatures.properties
    this.coordinates = accessFeatures.geometry.coordinates
    this.name = accessProperties.Name
    this.key = accessGeojson.name
    this.transportType = accessProperties?.transport || AccessTransportTypes.CAR
    this.link = accessProperties.link
  }

  getLink () {
    return this.link
  }

  getImageTransport () {
    return imagesTransportTypes[this.transportType]
  }

  getCoordinates () {
    return this.coordinates
  }

  getName () {
    return this.name
  }

  getKey () {
    return this.key
  }
}
