<template>
  <span/>
</template>

<script>
import * as Cesium from 'cesium/Cesium'
import Itinerary from '@/Classes/Itinerary'
import { viewerInstance } from '@/store/modules/viewer.js'
import { GLOBAL_PLAY_MODE, PLAYER_PLAY_MODE_GETTER, PLAYER_STORE_NAME } from '@/store'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      itinerary: null
    }
  },
  mounted () {
    this.renderItinerary(this.coordinates)
  },
  methods: {
    renderItinerary (coordinates) {
      if (this.itinerary) viewerInstance.entities.remove(this.itinerary)
      this.itinerary = viewerInstance.entities.add({
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(
            coordinates.map(coor => {
              return [coor[0], coor[1], coor[2] + 20]
            }).flat()
          ),
          width: 5,
          material: Itinerary.getColor(this.itineraryInfos).withAlpha(0.8)
          // material: Itinerary.getColor(this.itineraryInfos).withAlpha(0.6)
        },
        clampToGround: false
      })
      this.updateVisibility()
    },
    updateVisibility () {
      this.itinerary.polyline.show = this.playMode === GLOBAL_PLAY_MODE
    }
  },
  watch: {
    coordinates (newCoordinates) {
      this.renderItinerary(newCoordinates)
    },
    playMode () {
      this.updateVisibility()
    }
  },
  computed: {
    ...mapGetters(PLAYER_STORE_NAME, {
      playMode: PLAYER_PLAY_MODE_GETTER
    })
  },
  props: ['coordinates', 'itineraryInfos']
}
</script>
