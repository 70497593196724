<template>
  <span>
    <Access v-for="access in selectedAccesses" :accessData="access" :key="access.getKey()"/>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { GETTER_SELECTED_ACCESS_KEYS, GETTER_ACCESSES_DATA, ACCESS_STORE_NAME } from '@/store'
import Access from './Access'

export default {
  computed: {
    ...mapGetters(ACCESS_STORE_NAME, {
      selectedAccessKeys: GETTER_SELECTED_ACCESS_KEYS,
      accessesData: GETTER_ACCESSES_DATA
    }),
    selectedAccesses () {
      return this.accessesData.filter(access => {
        return this.selectedAccessKeys.includes(access.getKey())
      })
    }
  },
  components: { Access: Access }
}
</script>

<style>

</style>
