<template>
  <v-tooltip top color="#34495e">
    <template v-slot:activator="{ on, attrs }">
    <span v-bind:style="style" v-bind="attrs" v-on="on" class="timeline-cover"/>
    </template>
    <span>{{coverData.type}}<br>{{renderDistance(coverData.length)}}</span>
  </v-tooltip>
</template>

<script>
import { ITINERARY_MAX_DIST_GETTER, ITINERARY_STORE_NAME, PLAYER_STORE_NAME, PLAYER_GET_TIMELINE_METADATA } from '@/store'
import { mapGetters } from 'vuex'
import { renderPx, renderDistance } from '@/Utils'
import ItineraryData from '@/Classes/ItineraryData'

export default {
  props: ['coverData'],
  methods: {
    renderDistance: renderDistance
  },
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      itineraryMaxDistance: ITINERARY_MAX_DIST_GETTER
    }),
    ...mapGetters(PLAYER_STORE_NAME, {
      timelineMetadata: PLAYER_GET_TIMELINE_METADATA
    }),
    style () {
      const coverLength = this.coverData.length
      const coverMaxDistance = this.coverData.maxDistance
      const timelineWidth = this.timelineMetadata.width
      const width = Math.ceil((coverLength / coverMaxDistance) * timelineWidth)
      const offsetLeft = Math.ceil(((this.coverData.absoluteDistance - coverLength) / coverMaxDistance) * timelineWidth)
      return {
        background: ItineraryData.getColorFromCover(this.coverData),
        width: renderPx(width),
        left: renderPx(offsetLeft)
      }
    }
  }
}
</script>

<style lang="scss">
.timeline-cover {
  position: absolute;
  bottom: -25px;
  height: 20px;
  text-align: center;
  align-self: center;
  &:hover {
    cursor: pointer;
  }
}

.v-tooltip__content {
  opacity: 1 !important;
  z-index: 100 !important;
}

</style>
