import * as Cesium from 'cesium/Cesium'
import layers from '@/data/layers'

export const VIEWER_STORE_NAME = 'viewer'
export const VIEWER_GET_SELECTED_LAYER = 'getSelectedLayer'
export const VIEWER_GET_AVAILABLE_LAYERS = 'getAvailableLayers'
export const VIEWER_ACTION_SELECT_LAYER = `${VIEWER_STORE_NAME}/selectLayer`
export const VIEWER_GETTER_READY = 'getReady'
export const VIEWER_ACTION_ADD_LOADING_QUEUE = `${VIEWER_STORE_NAME}/addLoadingQueue`
export const VIEWER_ACTION_REMOVE_LOADING_QUEUE = `${VIEWER_STORE_NAME}/removeLoadingQueue`
export const VIEWER_ACTION_INITIALIZE = `${VIEWER_STORE_NAME}/initialize`

export const initialSelectedLayer = layers[0]
export var viewerInstance

const state = () => ({
  selectedLayer: initialSelectedLayer,
  availableLayers: layers,
  loadingQueue: -1 // Initialisé à -1 car aucun élément ajouté lors du montage
})

const getters = {
  getAvailableLayers: (state) => {
    return state.availableLayers
  },
  getSelectedLayer: (state) => {
    return state.selectedLayer
  },
  getReady: (state) => {
    return state.loadingQueue === 0
  }
}

const mutations = {
  selectLayer (state, layer) {
    state.selectedLayer = layer
  },
  addLoadingQueue (state, value) {
    state.loadingQueue = state.loadingQueue === -1 ? 1 : state.loadingQueue + 1
  },
  removeLoadingQueue (state, value) {
    state.loadingQueue -= 1
  },
  initialize () {
    viewerInstance = new Cesium.Viewer('cesiumContainer', {
      baseLayerPicker: false,
      fullscreenButton: false,
      homeButton: false,
      sceneModePicker: true,
      selectionIndicator: false,
      animation: false,
      timeline: false,
      geocoder: false,
      navigationInstructionsInitiallyVisible: false,
      navigationHelpButton: false,
      scene3DOnly: true,
      restrictGlobeCenter: true
    })
  }
}

const actions = {
  selectLayer ({ commit }, layer) {
    commit('selectLayer', layer)
  },
  addLoadingQueue ({ commit, state }, entity) {
    commit('addLoadingQueue', entity)
  },
  removeLoadingQueue ({ commit, state }) {
    commit('removeLoadingQueue', Math.max(state.loadingQueue - 1, 0))
  },
  addEntity (viewer, entity, name) {

  },
  async initialize ({ commit }) {
    await commit('initialize')
    return viewerInstance
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
