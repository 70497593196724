<template>
  <v-card :height="videoMode ? '70vh' : null">
    <v-carousel height="100%" :show-arrows="viewerPages.length > 1" v-model="model" hide-delimiters>
      <v-carousel-item v-for="(page, index) in viewerPages" :key="index">
        <iframe v-if="page.type === videoTypeLink && model === index" width="100%" height="100%" :src="page.url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <v-img height="100%" contain v-else :src="page.url" @load="emitLoadedImage">
            <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { PLAYER_STORE_NAME, PLAYER_PLAY_MODE_GETTER, VIDEO_PLAY_MODE, ACTION_HANDLE_VIEW_VIEWER_IMAGE_LOADED } from '@/store'
const videoTypeLink = 'video'
const photoTypeLink = 'image'

export default {
  props: ['videoLinks', 'photoLinks'],
  methods: {
    emitLoadedImage () {
      this.$store.dispatch(ACTION_HANDLE_VIEW_VIEWER_IMAGE_LOADED)
    }
  },
  data () {
    return {
      dialog: true,
      model: 0,
      videoTypeLink: videoTypeLink,
      photoTypeLink: photoTypeLink
    }
  },
  computed: {
    ...mapGetters(PLAYER_STORE_NAME, {
      playMode: PLAYER_PLAY_MODE_GETTER
    }),
    videoMode () {
      return this.playMode === VIDEO_PLAY_MODE
    },
    viewerPages () {
      const videoLinks = this.videoLinks?.map(link => {
        return {
          url: link,
          type: videoTypeLink
        }
      })

      const photoLinks = this.photoLinks?.map(link => {
        return {
          url: link,
          type: photoTypeLink
        }
      })

      let res = []
      if (videoLinks) res = res.concat(videoLinks)
      if (photoLinks) res = res.concat(photoLinks)
      return res
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        setTimeout(() => {
          this.showCarousel = false
        }, 500)
      } else {
        this.showCarousel = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-carousel .v-window-item {
    background: black;
  }

  .info-bar-action-btn {
    width: 100%
  }

</style>
