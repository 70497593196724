<template>
  <v-menu transition="slide-x-reverse-transition" left offset-x nudge-left="20" :close-on-content-click="false">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="{ ...tooltip, ...menu }"
          outlined
          x-large
          class="mb-2"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
        </template>
        Contrôle caméra
      </v-tooltip>
    </template>
    <div>
      <v-list dense>
      <v-list-item-group color="primary" v-model="selectedItem">
        <v-list-item @click="setPlayMode(GLOBAL_PLAY_MODE)">
          <v-list-item-icon><v-icon>mdi-earth</v-icon></v-list-item-icon>
          <v-list-item-content><span>Vue globale</span></v-list-item-content>
        </v-list-item>
        <v-list-item @click="setPlayMode(TRACKING_PLAY_MODE)">
          <v-list-item-icon><v-icon>mdi-airplane</v-icon></v-list-item-icon>
          <v-list-item-content><span>Vue tracking</span></v-list-item-content>
        </v-list-item>
        <v-list-item @click="setPlayMode(VIDEO_PLAY_MODE)">
          <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
          <v-list-item-content><span>Mode vidéo</span></v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      </v-list>
      <v-divider/>
      <v-btn @click="resetCamera" class="mt-2"><v-icon>mdi-camera-retake</v-icon> Réinitialiser caméra</v-btn>
    </div>
  </v-menu>
</template>

<script>
import { resetCamera } from '@/Utils'
import { ITINERARY_STORE_NAME, ITINERARY_GETTER_VIEWER, ITINERARY_GETTER, PLAYER_STORE_NAME, PLAYER_PLAY_MODE_GETTER, GLOBAL_PLAY_MODE, VIDEO_PLAY_MODE, TRACKING_PLAY_MODE, ACTION_SET_PLAY_MODE } from '@/store'
import { mapGetters } from 'vuex'
import { viewerInstance as viewer } from '@/store/modules/viewer.js'

export default {
  data () {
    return {
      selectedItem: 0,
      GLOBAL_PLAY_MODE: GLOBAL_PLAY_MODE,
      TRACKING_PLAY_MODE: TRACKING_PLAY_MODE,
      VIDEO_PLAY_MODE: VIDEO_PLAY_MODE
    }
  },
  methods: {
    resetCamera () {
      if (this.playMode !== GLOBAL_PLAY_MODE) {
        this.$store.dispatch(ACTION_SET_PLAY_MODE, GLOBAL_PLAY_MODE)
      }
      resetCamera(viewer, true)
      this.selectedItem = 0
    },
    setPlayMode (playMode) {
      this.$store.dispatch(ACTION_SET_PLAY_MODE, playMode)
    }
  },
  computed: {
    ...mapGetters(PLAYER_STORE_NAME, {
      playMode: PLAYER_PLAY_MODE_GETTER
    }),
    ...mapGetters(PLAYER_STORE_NAME, {
      playMode: PLAYER_PLAY_MODE_GETTER
    }),
    ...mapGetters(ITINERARY_STORE_NAME, {
      viewer: ITINERARY_GETTER_VIEWER,
      itinerary: ITINERARY_GETTER
    }),
    playModeIcon () {
      return this.playMode === GLOBAL_PLAY_MODE ? '' : ''
    }
  }
}
</script>

<style>

</style>
