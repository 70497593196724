<template>
  <div id='play-interface' v-bind:class="{ 'min': smallScreen }">
    <v-row align="center" no-gutters>
      <v-col lg="3" cols="12" v-if="showPlayerControl">
        <div class="player-controls">
          <ControlButton v-bind:style="{ 'width': '120px' }" :click="togglePlay" :icon="playing ? 'mdi-pause' : 'mdi-play'" :text="playing ? 'Pause' : 'Lecture'"/>
          <ControlButton :click="replay" icon="mdi-replay"/>
          <span v-bind:class="{ 'ml-3': smallScreen }" class="profile-info mr-3 category">{{itineraryInfos.category}}</span>
        </div>
      </v-col>
      <v-col>
      <div v-bind:class="{ 'video-snap': videoSnap, 'mt-3': smallScreen }" class="d-flex align-center">
        <span class="profile-info mr-3 distance"><template v-if="smallScreen">Dist.</template> <template v-else>Distance ↔</template> {{distance}}</span>
        <span class="profile-info mr-3 altitude"><template v-if="smallScreen">Alt.</template> <template v-else>Altitude ↗</template> {{altitude}}</span>
        <v-chip class="ml-3 cover" width="90" small dark :color="coverColor">{{cover}}</v-chip>
      </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ControlButton from './ControlButton'
import { PLAYER_STORE_NAME, PLAYER_STEP_GETTER, ACTION_TOGGLE_PLAY, ACTION_RESET_STEP, PLAYER_IS_PLAYING_GETTER, PLAYER_PLAY_MODE_GETTER, VIDEO_PLAY_MODE, ITINERARY_STORE_NAME, ITINERARY_PROFILE_GETTER, ITINERARY_GETTER_INFOS } from '@/store'
import { mapGetters } from 'vuex'
import Timeline from '@/Classes/Timeline'
import { renderDistance, renderAltitude, videoSnap } from '@/Utils'

export default {
  name: 'PlayInterface',
  data () {
    return {
      videoSnap: videoSnap
    }
  },
  methods: {
    togglePlay: function () {
      this.$store.dispatch(ACTION_TOGGLE_PLAY)
    },
    replay: function () {
      this.$store.dispatch(ACTION_RESET_STEP)
    }
  },
  computed: {
    ...mapGetters(PLAYER_STORE_NAME, {
      step: PLAYER_STEP_GETTER,
      playing: PLAYER_IS_PLAYING_GETTER,
      playMode: PLAYER_PLAY_MODE_GETTER
    }),
    ...mapGetters(ITINERARY_STORE_NAME, {
      itineraryInfos: ITINERARY_GETTER_INFOS,
      profile: ITINERARY_PROFILE_GETTER
    }),
    smallScreen () {
      const screenSize = this.$vuetify.breakpoint.name
      return screenSize === 'xs' || screenSize === 'sm'
    },
    showPlayerControl () {
      return !(videoSnap && this.playing && this.playMode === VIDEO_PLAY_MODE)
    },
    distance () {
      return renderDistance(Timeline.getDistance(this.profile, this.step))
    },
    altitude () {
      return renderAltitude(Timeline.getAltitude(this.profile, this.step))
    },
    cover () {
      return Timeline.getCoverType(this.profile, this.step)
    },
    coverColor () {
      return Timeline.getCoverColor(this.profile, this.step)
    }
  },
  components: { ControlButton: ControlButton }
}

</script>

<style lang='scss'>

@import 'scssVariables';

#play-interface {
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  margin-left: $margin-chart;
  &.min {
    margin-left: 10px;
    .profile-info {
      font-size: 1rem;
    }

  }
  .video-snap {
    .profile-info {
      font-size: 1.5rem;
    }
  }
  .player-controls {
    border-radius: 5px;
    .itinerary-category {
      font-size: 1.4rem;
    }
  }
  .profile-info {
    color: $color-cloud;
    font-size: 1.1rem;
  }
}

</style>
