<template>
  <span/>
</template>

<script>
import * as Cesium from 'cesium/Cesium'
import { clamp, resetCamera, getStepFromClockCurrentTime } from '@/Utils'
import { ACTION_SET_STEP, ITINERARY_STORE_NAME, ITINERARY_GETTER, PLAYER_STEP_GETTER, ITINERARY_GETTER_INFOS, PLAYER_PLAY_MODE_GETTER, VIDEO_PLAY_MODE, GLOBAL_PLAY_MODE, PLAYER_STORE_NAME, TRACKING_PLAY_MODE, PLAYER_IS_PLAYING_GETTER } from '@/store'
import { mapGetters } from 'vuex'
// import Itinerary from '@/Classes/Itinerary'
import { viewerInstance } from '@/store/modules/viewer.js'

const addHandlerPickLine = (scene, viewer, pickLineName, pickLinePositions, selectorCursor, event, movementPositionAttribute, callback) => {
  const handler = new Cesium.ScreenSpaceEventHandler(scene.canvas)
  handler.setInputAction((movement) => {
    const movementPosition = movement[movementPositionAttribute]
    const pickedEntity = scene.pick(movementPosition)
    if (Cesium.defined(pickedEntity)) {
      const entityName = pickedEntity.id.name
      if (!!entityName && entityName === pickLineName) {
        // Récupérer l'indice du point de la ligne cliquée
        const ray = viewer.camera.getPickRay(movementPosition)
        const mouse3Dposition = viewer.scene.globe.pick(ray, scene)

        const mappedDistance = pickLinePositions.map((position) => Cesium.Cartesian3.distance(position, mouse3Dposition))
        const minDistanceIndex = mappedDistance.indexOf(Math.min.apply(Math, mappedDistance))
        callback(minDistanceIndex)
      }
    }
  }, event)
}

const samplePositions = (coordinates, start, samplingStep, targetProportionIndex) => {
  const res = new Cesium.SampledPositionProperty()
  for (let index = 0; index < coordinates.length + samplingStep; index += samplingStep) {
    const tempIndex = clamp(index, 0, coordinates.length - 1)

    const targetCameraStep = clamp(tempIndex + Math.ceil(samplingStep * (targetProportionIndex || 1)), 0, coordinates.length - 1)
    const position = Cesium.Cartesian3.fromDegrees(
      coordinates[tempIndex][0],
      coordinates[tempIndex][1],
      coordinates[tempIndex][2] + 22
    )

    if (targetProportionIndex) {
      const startClone = new Cesium.JulianDate()
      Cesium.JulianDate.clone(start, startClone)
      const timeTarget = Cesium.JulianDate.addSeconds(
        startClone,
        targetCameraStep,
        new Cesium.JulianDate()
      )
      res.addSample(timeTarget, position)
    }

    const timeEnd = Cesium.JulianDate.addSeconds(
      start,
      tempIndex,
      new Cesium.JulianDate()
    )

    res.addSample(timeEnd, position)
  }
  return res
}

const getAvailability = (start, stop) => {
  return new Cesium.TimeIntervalCollection([
    new Cesium.TimeInterval({
      start: start,
      stop: stop
    })
  ])
}

const transparentPoint = {
  pixelSize: 0,
  color: Cesium.Color.TRANSPARENT
}

const getEntityPositionInAnimation = (start, entity, step) => {
  const currentTime = new Cesium.JulianDate()
  Cesium.JulianDate.addSeconds(start, step, currentTime)
  let res = new Cesium.Cartesian3()
  res = entity.position.getValue(currentTime, res)
  return res
}

const setTrackingCamera = (clock, start, stop, cameraLookAtRail, currentClockStep, viewer) => {
  const clockStep = getStepFromClockCurrentTime(clock)

  if (currentClockStep === clockStep) return

  currentClockStep = clockStep
  const currentPosition = getEntityPositionInAnimation(start, cameraLookAtRail, clockStep)
  if (!currentPosition) return

  // Déterminer le vecteur tangent à la position courante passant par l'itinéraire
  // Récupérer les deux points passant par le vecteur tangent
  const backStep = clamp(clockStep - trackDirectionStepWindow, 0, clockStep)
  const backPosition = getEntityPositionInAnimation(start, cameraLookAtRail, backStep)
  const tresholdStep = Cesium.JulianDate.secondsDifference(stop, start) - 1
  const nextStep = clamp(clockStep + trackDirectionStepWindow, clockStep, tresholdStep)
  const nextPosition = getEntityPositionInAnimation(start, cameraLookAtRail, nextStep)
  // Mettre les deux points approximativement à la même altitude
  // Instancier le vecteur tangent
  let directionVector = new Cesium.Cartesian3()
  directionVector = Cesium.Cartesian3.subtract(nextPosition, backPosition, directionVector)
  directionVector = Cesium.Cartesian3.normalize(directionVector, directionVector)

  // Déterminer le vecteur vertical approximatif
  const upVector = new Cesium.Cartesian3()
  Cesium.Cartesian3.subtract(currentPosition, new Cesium.Cartesian3(0, 0, -30), upVector)
  Cesium.Cartesian3.normalize(upVector, upVector)

  // Déterminer le vecteur normal au plan décrit par le vecteur tangent et le vecteur vertical
  const normalVector = new Cesium.Cartesian3()
  Cesium.Cartesian3.cross(directionVector, upVector, normalVector)
  Cesium.Cartesian3.normalize(normalVector, normalVector)

  // Positionner la caméra à la transversale du point courant
  const cameraPosition = currentPosition.clone()
  const upOffsetVector = upVector.clone()
  Cesium.Cartesian3.multiplyByScalar(upOffsetVector, 2200, upOffsetVector)
  const directionOffsetVector = directionVector.clone()
  Cesium.Cartesian3.multiplyByScalar(directionOffsetVector, -1600, directionOffsetVector)
  const normalOffsetVector = normalVector.clone()
  Cesium.Cartesian3.multiplyByScalar(normalOffsetVector, 2000, normalOffsetVector)
  Cesium.Cartesian3.add(cameraPosition, upOffsetVector, cameraPosition)
  Cesium.Cartesian3.add(cameraPosition, directionOffsetVector, cameraPosition)
  Cesium.Cartesian3.add(cameraPosition, normalOffsetVector, cameraPosition)

  let cameraToTrackingPointVector = new Cesium.Cartesian3()
  cameraToTrackingPointVector = Cesium.Cartesian3.subtract(currentPosition, cameraPosition, cameraToTrackingPointVector)
  Cesium.Cartesian3.normalize(cameraToTrackingPointVector, cameraToTrackingPointVector)

  const camera = viewer.camera
  camera.setView({
    destination: cameraPosition,
    orientation: {
      direction: cameraToTrackingPointVector,
      up: upVector
    }
  })
}

const trackDirectionStepWindow = 500 // Nombre de step avec lequel on regarde le vecteur direction

export default {
  props: ['coordinates', 'start', 'stop'],
  data: function () {
    return {
      tracker: null,
      pickLine: null,
      pickLinePositions: [],
      passedPositions: [],
      cameraLookAtRail: null,
      cameraPositionRail: null,
      currentClockStep: null
    }
  },
  mounted () {
    this.renderTrackerPositions()
  },
  watch: {
    coordinates () {
      this.renderTrackerPositions()
    },
    step () {
      if (!this.playing && this.playMode !== GLOBAL_PLAY_MODE) {
        setTrackingCamera(viewerInstance.clock, this.start, this.stop, this.cameraLookAtRail, this.currentClockStep, viewerInstance)
      }
    },
    playMode (value, oldValue) {
      const globalPlayMode = value === GLOBAL_PLAY_MODE
      setTrackingCamera(viewerInstance.clock, this.start, this.stop, this.cameraLookAtRail, this.currentClockStep, viewerInstance)
      if (globalPlayMode && oldValue !== GLOBAL_PLAY_MODE) {
        resetCamera(viewerInstance, false)
      }
    }
  },
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      itinerary: ITINERARY_GETTER,
      itineraryInfos: ITINERARY_GETTER_INFOS
    }),
    ...mapGetters(PLAYER_STORE_NAME, {
      playMode: PLAYER_PLAY_MODE_GETTER,
      playing: PLAYER_IS_PLAYING_GETTER,
      step: PLAYER_STEP_GETTER
    })
  },
  methods: {
    renderTrackerPositions () {
      if (this.tracker) viewerInstance.entities.remove(this.tracker)
      if (this.pickLine) viewerInstance.entities.remove(this.pickLine)

      const sampledPositions = samplePositions(this.coordinates, this.start, 1)

      this.tracker = viewerInstance.entities.add({
        // Set the entity availability to the same interval as the simulation time.
        availability: getAvailability(this.start, this.stop),
        // Use our computed positions
        position: sampledPositions,
        // Automatically compute orientation based on position movement.
        // orientation: new Cesium.VelocityOrientationProperty(trackerSampledPositions),
        point: {
          pixelSize: 10,
          color: Cesium.Color.SPRINGGREEN,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 3,
          disableDepthTestDistance: 4000
        },
        path: {
          show: true,
          leadTime: 0,
          trailTime: 40000,
          width: 5,
          material: new Cesium.PolylineOutlineMaterialProperty({
            // color: Itinerary.getColor(this.itineraryInfos),
            // outlineColor: Itinerary.getColor(this.itineraryInfos),
            color: Cesium.Color.SPRINGGREEN,
            outlineColor: Cesium.Color.SPRINGGREEN,
            outlineWidth: 3
          })
        },
        clampToGround: true
      })

      // Positionner les points de suivi de la caméra
      // Sous-échantillonner les positions du parcours
      const cameraFocusPositions = samplePositions(this.coordinates, this.start, 150)
      this.cameraLookAtRail = viewerInstance.entities.add({
        availability: getAvailability(this.start, this.stop),
        show: true,
        // Use our computed positions
        position: cameraFocusPositions,
        point: transparentPoint,
        viewFrom: new Cesium.Cartesian3(2000, 2000, 3500)
      })

      // Positionner la caméra en la déportant à l'extérieur du parcours
      viewerInstance.clock.onTick.addEventListener((clock) => {
        if (this.playing && (this.playMode === TRACKING_PLAY_MODE || this.playMode === VIDEO_PLAY_MODE)) {
          setTrackingCamera(clock, this.start, this.stop, this.cameraLookAtRail, this.currentClockStep, viewerInstance)
        }
      })

      const pickLineName = 'pickLine'

      // Ajouter les points d'ancrage pou la selection d'un point d'itinéraire
      this.pickLinePositions = Cesium.Cartesian3.fromDegreesArrayHeights(
        this.coordinates.map(coor => {
          return [coor[0], coor[1], coor[2] + 22]
        }).flat()
      )

      this.pickLine = viewerInstance.entities.add({
        name: pickLineName,
        polyline: {
          positions: this.pickLinePositions,
          show: true,
          width: 25,
          material: Cesium.Color.GREEN.withAlpha(0.01),
          disableDepthTestDistance: 10000000
        },
        clampToGround: false
      })

      const selectorCursor = viewerInstance.entities.add({
        point: {
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          show: true,
          pixelSize: 12,
          color: Cesium.Color.CRIMSON
        }
      })

      const scene = viewerInstance.scene

      // Positionner l'automatic playline sur le point de l'itinéraire cliqué
      addHandlerPickLine(scene, viewerInstance, pickLineName, this.pickLinePositions, selectorCursor, Cesium.ScreenSpaceEventType.LEFT_CLICK, 'position', (clickedStep) => {
        this.$store.dispatch(ACTION_SET_STEP, clickedStep)
      })
    }
  }
}
</script>
