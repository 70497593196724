import { renderPx, clamp } from '@/Utils'
import ItineraryData from '@/Classes/ItineraryData'

const PADDING_LABEL = 6
const WIDTH_LABEL = 150

export default class PlayLine {
  constructor (parent, height, width, margin, isAutomatic, showAltDist, filledArea) {
    this.parent = parent

    this.htmlElement = parent
      .append('div')
      .attr('class', 'vertical-line')
      .style('position', 'absolute')
      .style('z-index', '19')
      .style('width', '2px')
      .style('height', renderPx(height))
      .style('bottom', renderPx(margin.bottom))
      .style('left', renderPx(margin.left))
      .style('background', 'white')

    this.label = this.htmlElement.append('g')
      .style('position', 'absolute')
      .style('font-size', '.8rem')
      .style('width', renderPx(WIDTH_LABEL))
      .style('padding', renderPx(PADDING_LABEL))
      .style('padding', renderPx(PADDING_LABEL))
      .style('bottom', renderPx(0))

    this.showAltDist = showAltDist

    if (this.showAltDist) {
      this.labelDiv = this.label
        .append('div')

      this.altSection = this.labelDiv
        .append('div')
        .attr('class', 'vertical-line-section')

      this.altSymbol = this.altSection
        .append('text')

      this.altSymbol.text('alt. ')

      this.altText = this.altSection
        .append('text')

      this.distSection = this.labelDiv
        .append('text')
        .attr('class', 'vertical-line-section')

      this.distSymbol = this.distSection
        .append('text')

      this.distSymbol.text('dist. ')

      this.distText = this.distSection
        .append('text')
    }

    this.isAutomatic = isAutomatic

    this.filledArea = []
    // Remplir la surface derrière la ligne de lecture
    if (filledArea) {
      for (let iVertLine = 0; iVertLine < filledArea.length; iVertLine++) {
        const areaFillLine = filledArea[iVertLine]
        this.filledArea.push(this.parent
          .append('div')
          .attr('class', 'vertical-line')
          .style('position', 'absolute')
          .style('width', '1px')
          .style('height', renderPx(areaFillLine.height))
          .style('bottom', renderPx(margin.bottom))
          .style('left', this.getPixPositionLine(areaFillLine.position, margin, width))
          .style('background', '#bdc3c7'))
      }
    }
  }

  getPixPositionLine (position, margin, width) {
    return renderPx(clamp(position + margin.left, margin.left, width + margin.left))
  }

  setPosition (position, width, marginOffset, labelData, lineHeight) {
    const tresholdSetLabelLeft = WIDTH_LABEL
    this.htmlElement.style('left', this.getPixPositionLine(position, marginOffset, width))
    // Mettre à jour les labels
    if (position < width - marginOffset.right - tresholdSetLabelLeft) {
      this.label
        .style('left', 0)
        .style('text-align', 'left')
    } else {
      this.label
        .style('left', renderPx(-WIDTH_LABEL - 2 * PADDING_LABEL))
        .style('text-align', 'right')
    }

    if (this.showAltDist) {
      const data = new ItineraryData(labelData)
      this.altText.text(data.getFormattedAltitude())
      this.distText.text(data.getFormattedDistance())
    }

    for (let i = 0; i < this.filledArea.length; i++) {
      const line = this.filledArea[i]
      const opacity = (i <= position) ? 1 : 0
      line.style('opacity', opacity)
    }

    this.htmlElement
      .style('height', renderPx(lineHeight))
  }

  hide () {
    this.htmlElement
      .style('display', 'none')
  }

  show () {
    this.htmlElement
      .style('display', 'block')
  }
}
