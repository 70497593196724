<template>
  <v-dialog fullscreen v-model="renderedShowInfo" hide-overlay width="80vw" transition="dialog-bottom-transition">
    <v-card color="#2c3e50">
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['showInfo', 'setShowInfo'],
  computed: {
    renderedShowInfo: {
      get () {
        return this.showInfo
      },
      set (value) {
        this.setShowInfo(value)
      }
    }
  }
}
</script>

<style>

</style>
