import ItineraryData from '@/Classes/ItineraryData'
import PlayLine from '../components/ItineraryPlayerContainer/Timeline/Class/PlayLine'

export default class Timeline {
  constructor (containerId, d3, data, yMinMargin, borderMargins) {
    const container = document.getElementById(containerId.substring(1))
    while (container.firstChild) {
      container.removeChild(container.lastChild)
    }

    this.containerId = containerId
    this.d3 = d3
    this.data = data
    this.yMinMargin = yMinMargin
    this.timelineElement = this.d3.select(this.containerId)
    this.parentelement = this.timelineElement.node()
    this.margin = borderMargins
    this.width = this.parentelement.getBoundingClientRect().width - this.margin.left - this.margin.right
    this.height = this.parentelement.getBoundingClientRect().height - this.margin.top - this.margin.bottom
    this.userPlayLine = new PlayLine(this.timelineElement, this.height, this.width, this.margin, false, true)

    this.x = this.d3.scaleLinear().range([0, this.width])
    this.y = this.d3.scaleLinear().range([this.height, 0])
    this.x.clamp(true)
  }

  initialize (smallScreen) {
    // Set chart dimensions depending on axis margin and parent element
    const x = this.x
    const y = this.y
    const xAxis = this.d3.axisBottom()
      .scale(x).ticks(smallScreen ? 5 : 10).tickFormat((d) => {
        return `${d / 1000} km`
      })
    const yAxis = this.d3.axisLeft()
      .scale(y).ticks(5).tickFormat((d) => {
        return `${d} m`
      })

    const area = this.d3.area()
      .x(function (d) {
        return x(d.dist)
      })
      .y0(this.height)
      .y1(function (d) {
        return y(d.alts)
      })

    const svg = this.timelineElement
      .append('svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')

    x.domain(
      this.d3.extent(this.data, function (d) {
        return d.dist
      })
    )

    this.maxY = this.d3.max(this.data, function (d) {
      return d.alts
    })
    y.domain([this.maxY * this.yMinMargin, this.maxY])
    svg
      .append('path')
      .datum(this.data)
      .attr('class', 'area')
      .attr('d', area)
    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(xAxis)
    svg
      .append('g')
      .attr('class', 'y axis')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '.71em')
      .style('text-anchor', 'end')
      .text('Elevation')
    this.hideUserPlayLine()

    // Définir la hauteur de la surface à remplir derriere la ligne de lecture
    const filledAreaPrevious = []
    for (let previousPixel = 0; previousPixel < this.width; previousPixel++) {
      const correspondingStep = this.getStepFromChartPosition(previousPixel - this.getHorizontalOffset())
      filledAreaPrevious.push({
        position: previousPixel,
        height: this.getPixelHeight(correspondingStep)
      })
    }

    this.automaticPlayLine = new PlayLine(this.timelineElement, this.height, this.width, this.margin, true, false, filledAreaPrevious)
    this.setPlayPosition(0, this.automaticPlayLine)

    return {
      x: x,
      width: this.width,
      nbData: this.data.length,
      marginLeft: this.margin.left
    }
  }

  setHoverStep (step) {
    this.userPlayLine.show()
    this.setPlayPosition(step, this.userPlayLine)
  }

  showUserPlayLine () {
    this.userPlayLine.show()
  }

  hideUserPlayLine () {
    this.userPlayLine.hide()
  }

  // Retourne la distance de la donnée
  static getDistance (data, index) {
    return data[index].dist
  }

  // Retourne l'altitude de la donnée
  static getAltitude (data, index) {
    return data[index].alts
  }

  static getCoverType (data, index) {
    return data[index].cover?.type
  }

  static getCoverColor (data, index) {
    return data[index].cover ? ItineraryData.getColorFromCover(data[index].cover) : null
  }

  /**
   * Consulter les informations de l'itinéraire par rapport à la position de la
   * souris sur la frise
   * @param {*} mouseX coordonnées souris X relativement à la timeline
   * @param {*} mouseY coordonnées souris Y relativement à la timeline
   */
  lookAtMousePosition (mouseX, mouseY) {
    this.setPlayPosition(this.getStepFromChartPosition(mouseX), this.userPlayLine)
  }

  getStepFromChartPosition (mouseX) {
    const x0 = this.x.invert(this.getPositionInChart(mouseX))
    const bisect = this.d3.bisector(d => {
      const data = new ItineraryData(d)
      return data.getDistance()
    })
    return bisect.left(this.data, x0)
  }

  setStepFromChartPosition (mouseX) {
    const step = this.getStepFromChartPosition(mouseX)
    this.setPlayPosition(step, this.automaticPlayLine)
  }

  /**
   * Positionne le curseur de lecture sur la position de la souris sur la frise
   * @param {*} mouseX coordonnées souris X relativement à la timeline
   * @param {*} mouseY coordonnées souris Y relativement à la timeline
   */
  goToMousePosition (mouseX, mouseY) {
    this.setPlayPosition(this.getPositionInChart(mouseX), this.automaticPlayLine)
  }

  getPositionInChart (mouseX) {
    return mouseX + this.getHorizontalOffset()
  }

  handleAutomaticPlayPosition (step) {
    this.setPlayPosition(step, this.automaticPlayLine)
  }

  /**
   * Calcule la hauteur en pixel d'un élement du profil d'élévation
   * @param {*} step l'indice de l'étape
   */
  getPixelHeight (step) {
    const alt = Timeline.getAltitude(this.data, step)
    const minValueInChart = this.y.invert(this.height)
    var stepHeight
    if (step === 0) {
      stepHeight = this.height
    } else {
      stepHeight = ((alt - minValueInChart) / (this.maxY - minValueInChart)) * (this.height)
    }
    return stepHeight
  }

  getHorizontalOffset () {
    return -this.parentelement.getBoundingClientRect().x - this.margin.left
  }

  setPlayPosition (step, playLine) {
    // Accéder aux informations à afficher sur le label de la timeline
    const dist = Timeline.getDistance(this.data, step)
    const alt = Timeline.getAltitude(this.data, step)

    // Définir la hauteur en pixel de la barre
    const lineHeight = this.getPixelHeight(step)

    const labelData = ItineraryData.createData(dist, alt)
    const maxDistance = Timeline.getDistance(this.data, this.data.length - 1)
    const positionInChart = (dist / maxDistance) * this.width

    playLine.setPosition(positionInChart, this.width, this.margin, labelData, lineHeight)
  }

  setPointer (value, htmlElement) {
    htmlElement.style.cursor = value ? 'pointer' : 'auto'
  }
}
