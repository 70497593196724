import Access from '@/Classes/Access'

export const ACCESS_STORE_NAME = 'access'
export const GETTER_SELECTED_ACCESS_KEYS = 'getSelectedAccessKeys'
export const ACCESS_ACTION_SELECT_ACCESS = `${ACCESS_STORE_NAME}/selectAccess`
export const ACCESS_ACTION_UNSELECT_ACCESS = `${ACCESS_STORE_NAME}/unSelectAccess`
export const ACCESS_ACTION_SET_SELECTED = `${ACCESS_STORE_NAME}/setSelectedAccess`
export const GETTER_ACCESSES_DATA = 'getAccessesData'
export const ACCESS_ACTION_UNSELECT_ALL_ACCESS = `${ACCESS_STORE_NAME}/unSelectAllAccess`

const accessKeys = [
  'evolene_hauderes', 'hauderes_village_bas', 'zone_2', 'zone_3', 'zone_7', 'zone_8', 'zone_10'
]

const state = () => ({
  selectedAccessKeys: [],
  accessesData: accessKeys.map(accessKey => {
    const accessGeojson = require(`@/data/accesses/${accessKey}.json`)
    return new Access(accessGeojson)
  })
})

const getters = {
  getSelectedAccessKeys: (state) => {
    return state.selectedAccessKeys
  },
  getAccessesData: (state) => {
    return state.accessesData
  }
}

const mutations = {
  selectAccess (state, access) {
    state.selectedAccessKeys = state.selectedAccessKeys.filter((value) => value !== access.getKey())
  },
  unSelectAccess (state, access) {
    if (!access) {
      state.selectedAccessKeys = []
      return
    }
    const accessKey = access.getKey()
    if (!state.selectedAccessKeys.includes(accessKey)) {
      state.selectedAccessKeys = [...accessKey, state.selectedAccessKeys]
    }
  },
  setSelectedAccess (state, value) {
    state.selectedAccessKeys = value
  }
}

const actions = {
  selectedAccess ({ commit }, value) {
    commit('selectAccess', value)
  },
  unSelectAccess ({ commit }, value) {
    commit('unSelectAccess', value)
  },
  setSelectedAccess ({ commit }, value) {
    commit('setSelectedAccess', value)
  },
  unSelectAllAccess ({ commit }) {
    commit('setSelectedAccess', [])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
