<template>
  <v-btn
  @click='onClick'
  elevation="3"
  :fab="!text"
  :small="!text"
  class='control-button'
  ><v-icon>{{icon}}</v-icon> {{text}}</v-btn>
</template>

<script>

export default {
  props: ['icon', 'click', 'text'],
  methods: {
    onClick: function () {
      this.click()
    }
  }
}
</script>

<style lang="scss">

.control-button {
  margin-right: .25rem
}

</style>
