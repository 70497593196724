<template>
  <v-menu transition="slide-x-reverse-transition" left nudge-left="20" offset-x :close-on-content-click="false" :close-on-click="!!itinerarySelected" :value="!itinerarySelected" rounded="x-lg" min-width="300">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip }">
        <v-btn
          dark
          icon
          v-bind="attrs"
          v-on="{ ...tooltip, ...menu }"
          outlined
          x-large
        >
          <v-icon>mdi-swap-horizontal-variant</v-icon>
        </v-btn>
        </template>
        Itinéraires
      </v-tooltip>
    </template>
    <v-list>
      <v-subheader>ITINERAIRES</v-subheader>
      <template v-for="(itinerary, index) in itineraries">
        <ItineraryInfos :key="itinerary.id" :itinerary="itinerary"/>
        <v-divider v-if="index + 1 < itineraries.length" :key="'divider'+itinerary.id"/>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { itineraries } from '@/Classes/Itinerary'
import { ITINERARY_STORE_NAME, ITINERARY_GETTER } from '@/store'
import ItineraryInfos from './ItineraryInfos'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    model: 0,
    itineraries: itineraries
  }),
  props: ['showItineraries'],
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      itinerarySelected: ITINERARY_GETTER
    })
  },
  components: { ItineraryInfos: ItineraryInfos }
}
</script>

<style lang="scss">
@import 'scssVariables';

.v-menu__content {
background: $color-cloud;
}
</style>
