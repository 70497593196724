<template>
  <v-app id="app">
    <template v-if='canShowViews'>
      <View3D key="view3D"/>
      <ItineraryPlayerContainer key="playercontainer"/>
      <InfoContainer key="infoContainer"/>
    </template>
    <div v-if="!viewer3DReady" id="background-container" class="d-flex justify-center align-self-center align-center">
      <transition name="fade">
      <div v-show="watchLoadingState">
        <v-row justify='center' align="center" no-gutters>
          <v-img class="fading-loading" src="/logo.svg" v-on:load="decrementLoadingIcon" contain max-height="100" max-width="300"/>
        </v-row>
        <div class="instructions-container" v-if="!smallScreen" v-bind:class="{ 'min': smallScreen }">
        <v-row justify='space-between'>
          <v-col>
            <v-row justify='center' align="center" no-gutters>
              <v-img src="/click_left.svg" v-on:load="decrementLoadingIcon" max-width="100"/>
            </v-row>
            <v-row justify='center' align="center" no-gutters>
              <span v-show="loadingIcons === 0">Se déplacer</span>
            </v-row>
          </v-col>
          <v-col>
            <v-row justify='center' align="center" no-gutters>
              <v-img src="/zoom.svg" @load="() => { decrementLoadingIcon() }" max-width="100"/>
            </v-row>
            <v-row justify='center' align="center" no-gutters>
              <span v-show="loadingIcons === 0">Zoomer/Dézoomer</span>
            </v-row>
          </v-col>
          <v-col>
            <v-row justify='center' align="center" no-gutters>
              <v-img src="/click_roll.svg" v-on:load="decrementLoadingIcon" max-width="100"/>
            </v-row>
            <v-row justify='center' align="center" no-gutters>
              <span v-show="loadingIcons === 0">S'orienter</span>
            </v-row>
          </v-col>
        </v-row>
        </div>
      </div>
      </transition>
      <transition name="fade">
      <v-btn v-if="showPlayButton" @click="showViews" color="#a73737" width="150" height="150" elevation="5" outlined fab icon x-large>
        <v-icon>mdi-play</v-icon><v-icon>mdi-video-3d</v-icon>
      </v-btn>
      </transition>
    </div>
  </v-app>
</template>

<script>
import ItineraryPlayerContainer from '@/components/ItineraryPlayerContainer'
import View3D from '@/components/View3D'
import InfoContainer from '@/components/InfoContainer'
import { mapGetters } from 'vuex'
import { ACTION_SET_ITINERARY, ACTION_SET_STEP, ITINERARY_ACTION_RESET, VIEWER_GETTER_READY, VIEWER_STORE_NAME, ACTION_TOGGLE_PLAY, VIEWER_ACTION_ADD_LOADING_QUEUE, VIEWER_ACTION_REMOVE_LOADING_QUEUE } from '@/store'
import { itineraries } from '@/Classes/Itinerary'

export default {
  name: 'App',
  data () {
    const waitActiveSceneQuery = this.$route?.query?.wait_active_scene
    const waitActiveScene = waitActiveSceneQuery !== null && waitActiveSceneQuery
    return {
      waitActiveScene: waitActiveScene,
      canShowViews: false,
      watchLoadingState: false,
      showPlayButton: waitActiveScene,
      loadingIcons: 4
    }
  },
  components: { ItineraryPlayerContainer: ItineraryPlayerContainer, InfoContainer: InfoContainer, View3D: View3D },
  mounted () {
    if (!this.waitActiveScene) {
      setTimeout(() => {
        this.showViews()
      }, 500)
    }
  },
  computed: {
    ...mapGetters(VIEWER_STORE_NAME, {
      viewer3DReady: VIEWER_GETTER_READY
    }),
    smallScreen () {
      const screenSize = this.$vuetify.breakpoint.name
      return screenSize === 'xs' || screenSize === 'sm'
    }
  },
  watch: {
    '$route.query.itinerary_key': {
      handler (key, oldKey) {
        if (key === oldKey) return
        if (key === 'undefined' || !key) {
          this.$store.dispatch(ITINERARY_ACTION_RESET)
          return
        }
        this.selectItinerary(key)
      },
      deep: true
    },
    viewer3DReady (value) {
      if (value && this.waitActiveScene) {
        setTimeout(() => {
          this.$store.dispatch(ACTION_TOGGLE_PLAY)
        }, 2000)
      }
    }
  },
  methods: {
    decrementLoadingIcon () {
      this.loadingIcons -= 1
    },
    showViews () {
      this.showPlayButton = false
      setTimeout(() => {
        this.watchLoadingState = true
      }, 500)
      this.$store.dispatch(VIEWER_ACTION_ADD_LOADING_QUEUE, 'viewer')
      setTimeout(() => {
        this.canShowViews = true
        this.getItineraryFromQuery()
        setTimeout(() => {
          this.$store.dispatch(VIEWER_ACTION_REMOVE_LOADING_QUEUE, 'viewer')
        }, 1000)
      }, 1000)
    },
    getItineraryFromQuery () {
      const itineraryKey = this.$route?.query?.itinerary_key
      if (itineraryKey) {
        this.selectItinerary(itineraryKey)
      }
    },
    async selectItinerary (key) {
      const itinerary = itineraries.find(sItinerary => {
        return sItinerary.dataKey === key
      })
      if (itinerary === -1) console.error(`App.vue: Itinerary with key ${key} not found`)
      await this.$store.dispatch(ACTION_SET_ITINERARY, itinerary)
      this.$store.dispatch(ACTION_SET_STEP, 0)
      // Tricky ! Emule un clic en dehors du menu pour le fermer
      setTimeout(() => {
        var evt = new MouseEvent('click')
        var cb = document.getElementById('close-menu-div')
        if (cb) cb.dispatchEvent(evt)
      }, 200)
    },
    lockCamera (cameraController) {
      cameraController.enableInputs = false
    },
    unlockCamera (cameraController) {
      cameraController.enableInputs = true
    }
  }
}
</script>
 <style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
#app,
#cesiumContainer {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: black;
  width: 100%;
  height: 100%;
}

.fading-loading {
  animation: fading 2.6s infinite;
  -webkit-animation: fading 2.6s infinite;
}

@keyframes fading {
    0%   { opacity: 0; }
    55%  { opacity: 1; }
    100% { opacity: 0; }
}

@-webkit-keyframes fading {
    0%   { opacity: 0; }
    55%  { opacity: 1; }
    100% { opacity: 0; }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.instructions-container {
  margin-top: 90px;
  width: 500px;
  color: #bdc3c7;
  font-weight: 600;
  &.min {
    width: 90vw;
  }
}

#background-container {
  width: 100%;
  z-index: 4000;
  height: 100%;
  position: fixed;
  background: #ece9e6; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ece9e6, #ffffff); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ece9e6, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  .v-icon {
    font-size: 60px;
  }
  // button {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  // }
}
</style>
