import ItineraryData from '@/Classes/ItineraryData'
import { computeDistance, getGeojsonPointCoordinates, setGeojsonPointHeight } from '@/Utils'
import Itinerary from '@/Classes/Itinerary'
import poisJson from '@/data/poi/pois.json'
import itineraryPoiFilters from '@/data/poi/itineraryPoiFilters.json'
import poisFacilities from '@/data/poi/facilities.json'
import POI from '@/Classes/POI'

export const ITINERARY_STORE_NAME = 'itinerary'
export const ITINERARY_GETTER = 'getItinerary'
export const ITINERARY_GETTER_VIEWER = 'getViewer'
export const ITINERARY_GETTER_INFOS = 'getItineraryInfos'
export const ITINERARY_GETTER_COVERS = 'getItineraryCovers'
export const ITINERARY_POIS_GETTER = 'getPOIS'
export const ITINERARY_MAX_DIST_GETTER = 'getMaxDistance'
export const ITINERARY_PROFILE_GETTER = 'getItineraryProfile'
export const IS_ITINERARY_LOADED = 'isItineraryLoaded'
export const ACTION_SET_ITINERARY = `${ITINERARY_STORE_NAME}/setItinerary`
export const ITINERARY_ACTION_UPDATE_POI = `${ITINERARY_STORE_NAME}/updatePOI`
export const ITINERARY_ACTION_SET_VIEWER = `${ITINERARY_STORE_NAME}/setViewer`
export const ITINERARY_ACTION_RESET = `${ITINERARY_STORE_NAME}/resetState`

const initialState = {
  viewer: null,
  currentItinerary: null,
  maxDistance: 0,
  itineraryLoaded: false,
  itineraryInfos: null,
  itineraryProfile: null,
  pois: [],
  itineraryCovers: []
}

const state = () => initialState

const getters = {
  getItineraryProfile: (state, getters, rootState) => {
    return state.itineraryProfile
  },
  getItineraryCovers: (state, getters, rootState) => {
    return state.itineraryCovers
  },
  getItineraryInfos: (state, getters, rootState) => {
    return state.itineraryInfos
  },
  getItinerary: (state, getters, rootState) => {
    return state.currentItinerary
  },
  isItineraryLoaded: (state, getters, rootState) => {
    return state.itineraryLoaded
  },
  getPOIS: (state) => {
    return state.pois
  },
  getMaxDistance: (state) => {
    return state.maxDistance
  },
  getViewer: (state) => {
    return state.viewer
  }
}

const mutations = {
  setItinerary (state, { itineraryInfos, currentItinerary, itineraryProfile, pois, itineraryCovers }) {
    state.currentItinerary = currentItinerary
    state.itineraryProfile = itineraryProfile
    state.itineraryInfos = itineraryInfos
    if (itineraryProfile.length > 0) {
      state.maxDistance = itineraryProfile.reduce((a, b) => (a.dist > b.dist ? a.dist : b.dist))

      // Associer pour chaque step la couverture
      const copyCovers = [...itineraryCovers]

      for (const profileStep of itineraryProfile) {
        let coverAssigned = false
        let lastCover = null
        while (!coverAssigned) {
          if (copyCovers.length === 0) {
            coverAssigned = true
            profileStep.setCover(lastCover)
          } else {
            const cover = copyCovers[0]

            if (cover.absoluteDistance >= profileStep.getDistance()) {
              profileStep.setCover(cover)
              coverAssigned = true
              lastCover = cover
            } else {
              copyCovers.shift()
            }
          }
        }
      }
    }

    state.itineraryCovers = itineraryCovers
    state.pois = pois
    state.itineraryLoaded = true
  },
  updatePOI (state, poiToUpdate) {
    const correspondingPOIIndex = state.pois.findIndex(poi => poi.key === poiToUpdate.key)
    if (correspondingPOIIndex !== -1) {
      state.pois[correspondingPOIIndex] = poiToUpdate
    }
  },
  setViewer (state, viewer) {
    state.viewer = viewer
  },
  resetState (state) {
    state = initialState
  }
}

/**
 * Calcule le profile de l'itinéraire et les metadata des poi en fonction de l'itinéraire reçu sous format GeoJSON
 */
const createItineraryInfos = (geojsonItinerary, itineraryDataKey) => {
  const coordinatesSet = geojsonItinerary.features[0].geometry.coordinates[0]
  const profile = [ItineraryData.createData(0, coordinatesSet[0][2])]
  let distance = 0
  for (let iCoordinates = 0; iCoordinates < coordinatesSet.length - 1; iCoordinates++) {
    const coordinates = coordinatesSet[iCoordinates]
    const nextCoordinates = coordinatesSet[iCoordinates + 1]
    distance = distance + computeDistance(coordinates, nextCoordinates)
    profile.push(ItineraryData.createData(distance, nextCoordinates[2]))
  }

  // Créer les Pois
  const pois = poisJson.features.filter(poiJson => {
    const poiFilter = itineraryPoiFilters[poiJson.properties.key]
    if (!poiFilter) {
      return true
    }
    return !poiFilter.includes(itineraryDataKey)
  }).map(jsonPoi => {
    let altitude; let distance
    // Déterminer l'altitude et la distance du poi par rapport à l'itinéraire
    const poiProperties = jsonPoi.properties
    if (poiProperties.altitude) {
      altitude = poiProperties.altitude
    }
    const jsonPoiDistances = poiProperties.distances
    if (jsonPoiDistances && jsonPoiDistances[itineraryDataKey]) {
      distance = jsonPoiDistances[itineraryDataKey]
    }

    const poiJsonPosition = getGeojsonPointCoordinates(jsonPoi)
    const distanceMapElementFromPoi = coordinatesSet.map(coordinates => {
      // Annuler la coordonnées en z (sinon erreur de distance sur certains poi)
      // TOIMPROVE : Enlever tout ça et demander les coordonnées en altitude des POI et distance dans la course directement...
      return computeDistance(coordinates, poiJsonPosition, true)
    })
    const itineraryIndex = distanceMapElementFromPoi.indexOf(Math.min(...distanceMapElementFromPoi))

    // Déterminer la distance du poi par rapport à l'itinéraire : Le point de l'itinéraire le plus proche du POI
    if (!distance) {
      if (!altitude) altitude = profile[itineraryIndex].alts
      distance = profile[itineraryIndex].dist
    }

    setGeojsonPointHeight(jsonPoi, altitude)
    return new POI(jsonPoi, poisFacilities, distance, altitude, itineraryIndex)
  })

  return { profile: profile, pois: pois }
}

const actions = {
  setItinerary ({ commit }, itineraryInfos) {
    const itineraryData = Itinerary.getCorrespondingData(itineraryInfos)
    const itineraryKey = itineraryInfos.dataKey
    const itineraryCovers = Itinerary.getCover(itineraryKey)
    const { profile, pois } = createItineraryInfos(itineraryData, itineraryKey)
    commit('setItinerary', { itineraryInfos: itineraryInfos, currentItinerary: itineraryData, itineraryProfile: profile, pois: pois, itineraryCovers: itineraryCovers })
    return 0
  },
  updatePOI ({ commit }, poi) {
    commit('updatePOI', poi)
  },
  setViewer ({ commit }, viewer) {
    commit('setViewer', viewer)
  },
  resetState ({ commit }) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
