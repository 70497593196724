/* eslint-disable quote-props */
/* eslint-disable no-template-curly-in-string */
export default {
  show: true,
  labelStyle: 2,
  labelText: '${DISPLAY_TEXT}',
  disableDepthTestDistance: 6000,
  anchorLineEnabled: true,
  anchorLineColor: "color('white')",
  heightOffset: {
    conditions: [
      ['${LOD} === "7"', 300],
      ['${LOD} === "6"', 300],
      ['${LOD} === "5"', 400],
      ['${LOD} === "4"', 400],
      ['${LOD} === "3"', 500],
      ['${LOD} === "2"', 600],
      ['${LOD} === "1"', 700],
      ['${LOD} === "0"', 800],
      ['true', '800']
    ]
  },
  labelColor: {
    conditions: [
      ['${OBJEKTART} === "See"', 'color("teal")'],
      ['true', 'color("black")']
    ]
  },
  labelOutlineColor: 'color("white", 1)',
  labelOutlineWidth: 5,
  font: {
    conditions: [
      ['${OBJEKTART} === "See"', '"bold 32px arial"'],
      ['${OBJEKTART} === "Alpiner Gipfel"', '"italic 32px arial"'],
      ['true', '" 32px arial"']
    ]
  },
  scaleByDistance: {
    conditions: [
      ['${LOD} === "7"', 'vec4(1000, 1, 5000, 0.4)'],
      ['${LOD} === "6"', 'vec4(1000, 1, 5000, 0.4)'],
      ['${LOD} === "5"', 'vec4(1000, 1, 8000, 0.4)'],
      ['${LOD} === "4"', 'vec4(1000, 1, 10000, 0.4)'],
      ['${LOD} === "3"', 'vec4(1000, 1, 20000, 0.4)'],
      ['${LOD} === "2"', 'vec4(1000, 1, 30000, 0.4)'],
      ['${LOD} === "1"', 'vec4(1000, 1, 50000, 0.4)'],
      ['${LOD} === "0"', 'vec4(1000, 1, 500000, 0.4)'],
      ['true', 'vec4(1000, 1, 10000, 0.4)']
    ]
  },
  translucencyByDistance: {
    conditions: [
      ['${LOD} === "7"', 'vec4(5000, 1, 5001, 1)'],
      ['${LOD} === "6"', 'vec4(5000, 1, 5001, 1)'],
      ['${LOD} === "5"', 'vec4(5000, 1, 8000, 0.8)'],
      ['${LOD} === "4"', 'vec4(5000, 1, 10000, 0.8)'],
      ['${LOD} === "3"', 'vec4(5000, 1, 20000, 0.6)'],
      ['${LOD} === "2"', 'vec4(5000, 1, 30000, 0.6)'],
      ['${LOD} === "1"', 'vec4(5000, 1, 50000, 0.5)'],
      ['${LOD} === "0"', 'vec4(5000, 1, 500000, 1)'],
      ['true', 'vec4(5000, 1, 10000, 0.5)']
    ]
  },
  distanceDisplayCondition: {
    'conditions': [
      ['${LOD} === "7"', 'vec2(0, 20000)'],
      ['${LOD} === "6"', 'vec2(0, 20000)'],
      ['${LOD} === "5"', 'vec2(0, 20000)'],
      ['${LOD} === "4"', 'vec2(0, 20000)'],
      ['${LOD} === "3"', 'vec2(0, 20000)'],
      ['${LOD} === "2"', 'vec2(0, 20000)'],
      ['${LOD} === "1"', 'vec2(0, 20000)'],
      ['${LOD} === "0"', 'vec2(0, 20000)'],
      ['${OBJEKTART} === "Alpiner Gipfel"', 'vec2(0, 100000)']
    ]
  }
}
