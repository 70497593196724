import { geojsonCoordinates2Cartesian3 } from '@/Utils'

import rescue from '@/assets/icons/rescue.png'
import drink from '@/assets/icons/drink.png'
import time from '@/assets/icons/time.png'
import tools from '@/assets/icons/tools.png'
import start from '@/assets/icons/start.png'

export const FEED_TECH_TYPE = 'feed_tech'
export const PARKING_TYPE = 'parking'
export const VIEW_TYPE = 'view'
const FACILITY_START = 'START'
const FACILITY_END = 'END'
const FACILITY_TYPE_TECH = 'TECH'
const FACILITY_TYPE_FOOD = 'FOOD'
const FACILITY_TYPE_RESCUE = 'RESCUE'
const FACILITY_TYPE_TIME = 'TIME'
const FACILITY_TYPE_UNDEFINED = 'UNDEFINED'

class Facility {
  constructor (key) {
    this.key = key
    this.isStart = key === FACILITY_START
    this.isEnd = key === FACILITY_END
    // Associer l'url de l'image correspondant à l'installation
    switch (this.key) {
      case FACILITY_TYPE_TECH:
        this.imageUrl = tools
        this.tooltip = 'Assistance technique'
        break
      case FACILITY_TYPE_FOOD:
        this.imageUrl = drink
        this.tooltip = 'Ravitaillement'
        break
      case FACILITY_TYPE_RESCUE:
        this.imageUrl = rescue
        this.tooltip = 'Samaritain'
        break
      case FACILITY_TYPE_TIME:
        this.imageUrl = time
        this.tooltip = 'Temps intermédiaire'
        break
      case FACILITY_START:
        this.imageUrl = start
        this.tooltip = 'Départ'
        break
      case FACILITY_END:
        this.imageUrl = start
        this.tooltip = 'Arrivée'
        break
      default:
        this.imageUrl = FACILITY_TYPE_UNDEFINED
        console.error(`POI.js : Facility L'installation ayant pour clé ${key} n'a pas d'image associée`)
    }
  }
}

const parkingImage = require('@/assets/icons/parking.png')
const viewImage = require('@/assets/icons/photo.png')

export default class POI {
  constructor (jsonPOI, poisFacilities, distance, altitude, itineraryIndex) {
    // S'assurer que la feature json possède un champs "key" dans ses properties
    const poiProperties = jsonPOI.properties
    this.key = poiProperties.key
    this.name = poiProperties.name
    this.label = poiProperties.mapLabel
    this.accessKey = poiProperties.accessKey
    this.type = poiProperties.type
    this.description = poiProperties.description
    this.videoLinks = poiProperties.videoLinks
    this.photoLinks = poiProperties.photoLinks || []
    this.altitude = altitude
    this.itineraryIndex = itineraryIndex
    const coordinates = geojsonCoordinates2Cartesian3(jsonPOI.geometry.coordinates)
    this.cartesianCoordinates = coordinates
    this.isItineraryStart = false
    this.isItineraryEnd = false
    this.facilities = []

    // Si le poi est une zone, associer les installations
    if (this.type === FEED_TECH_TYPE) {
      this.facilities = poisFacilities[poiProperties.key].map(poiKey => {
        this.isItineraryStart = this.isItineraryStart || poiKey === FACILITY_START
        this.isItineraryEnd = this.isItineraryEnd || poiKey === FACILITY_END
        return new Facility(poiKey)
      })
    }
    this.focusOnVideo = this.photoLinks.length > 0
    this.distance = distance
    this.cesiumEntity = null

    switch (this.type) {
      case PARKING_TYPE:
        this.image = parkingImage
        break
      case VIEW_TYPE:
        this.image = viewImage
        break
    }
  }

  canRender () {
    switch (this.type) {
      case FEED_TECH_TYPE:
      case PARKING_TYPE:
      case VIEW_TYPE:
        return true
      default:
        return false
    }
  }

  canRenderLabel () {
    return this.type === FEED_TECH_TYPE
  }

  hasAccess () {
    return this.accessKey !== null
  }

  getAccessKey () {
    return this.accessKey
  }

  setCesiumEntity (cesiumEntity) {
    this.cesiumEntity = cesiumEntity
  }
}
