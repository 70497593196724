<template>
  <div>
    <template v-if='isItineraryLoaded'>
      <div id="itinerary-player-container" v-bind:class="{ 'min': smallScreen }">
        <PlayInterface/>
        <Timeline/>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ITINERARY_STORE_NAME, IS_ITINERARY_LOADED } from '@/store'

import Timeline from './Timeline'
import PlayInterface from './PlayInterface'

export default {
  data () {
    return {
      itinerarySelectorVisible: true
    }
  },
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      isItineraryLoaded: IS_ITINERARY_LOADED
    }),
    smallScreen () {
      const screenSize = this.$vuetify.breakpoint.name
      return screenSize === 'xs' || screenSize === 'sm'
    }
  },
  components: { Timeline: Timeline, PlayInterface: PlayInterface }
}
</script>

<style lang="scss">
@import 'scssVariables';

#itinerary-player-container {
  position: absolute;
  padding-top: 20px;
  bottom: 0;
  height: $player-height;
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  background: linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.6));
  &.min {
    height: $player-height-min;
  }
}
</style>
