<template>
  <span>
    <template v-for="poi in this.pois">
      <POIComponent v-if='poi.canRender()' :key="poi.key" :poiData="poi"/>
    </template>
  </span>
</template>

<script>
import POIComponent from './POI'
import { ITINERARY_STORE_NAME, ITINERARY_POIS_GETTER } from '@/store'
import { FEED_TECH_TYPE } from '@/Classes/POI.js'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      FEED_TECH_TYPE: FEED_TECH_TYPE
    }
  },
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      pois: ITINERARY_POIS_GETTER
    })
  },
  mounted () {

  },
  components: { POIComponent: POIComponent }
}
</script>

<style>

</style>
