<template>
  <span/>
</template>

<script>
import * as Cesium from 'cesium/Cesium'
import { viewerInstance as viewer } from '@/store/modules/viewer.js'

export default {
  data () {
    return {
      initialized: false
    }
  },
  mounted () {
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(this.initialPosition[0], this.initialPosition[1], this.initialPosition[2]), // Evolène
      orientation: {
        heading: Cesium.Math.toRadians(230.0),
        pitch: Cesium.Math.toRadians(-55.0),
        roll: 0
      }
    })
  },
  props: ['initialPosition']
}
</script>
