<template>
  <v-col>
    <div class="information-container" v-bind:class="{ 'video-mode': videoMode }">
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <h3>{{selectedItemMetadata.name}}</h3>
            </v-col>
          </v-row>
          <template v-if="!videoMode">
          <v-row>
            <v-col>
              <label>Alt.</label>  <span>{{renderAltitude(selectedItemMetadata.altitude)}}</span>
            </v-col>
            <v-col>
              <label>Dist.</label>  <span>{{renderDistance(selectedItemMetadata.distance)}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
              <v-tooltip v-for="facility in selectedItemMetadata.facilities" :key="facility.key" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <img :src="facility.imageUrl"/>
                  </span>
                </template>
                <span>{{facility.tooltip}}</span>
              </v-tooltip>
          </v-row>
          </template>
          <v-row>
            <v-col>
            <ViewViewer v-if="selectedItemMetadata.videoLinks || selectedItemMetadata.photoLinks"
              :videoLinks="selectedItemMetadata.videoLinks" :videoMode="videoMode" :photoLinks="selectedItemMetadata.photoLinks"/>
            </v-col>
          </v-row>
          <template v-if="!videoMode">
          <v-row v-if="renderedDescription">
            <v-col>
              <p>{{renderedDescription}}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="selectedItemMetadata.accessKey">
              <v-btn class="info-bar-action-btn mb-2" v-if="webUrl" :href="correspondingAccessLink" target="_blank">
                <v-icon>mdi-google-maps</v-icon> MAPS
              </v-btn>
              <template v-else>
                <v-btn class="info-bar-action-btn mb-2" :href="correspondingAccessLink" target="_blank"><v-icon>mdi-download</v-icon> {{getExtension(correspondingAccessLink)}} </v-btn>
              </template>
            </v-col>
          </v-row>
          </template>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import { renderDistance, renderAltitude, getExtension } from '@/Utils'
import ViewViewer from './ViewViewer'

export default {
  components: { ViewViewer: ViewViewer },
  props: ['selectedItemMetadata', 'correspondingAccessLink', 'videoMode'],
  methods: {
    getExtension: getExtension,
    renderDistance: renderDistance,
    renderAltitude: renderAltitude
  },
  computed: {
    renderedDescription () {
      return this.selectedItemMetadata.description?.replace('<br>', '\n')
    },
    webUrl () {
      return this.correspondingAccessLink.includes('http')
    }
  }
}
</script>

<style lang="scss">
@import 'scssVariables';

.information-container {

  &.video-mode {
    h3 {
      font-size: 3rem;
    }
  }

  h3 {
    word-break: break-word;
    text-align: left;
  }
  color: $color-cloud;
  padding: .5rem .75rem;
  white-space: pre-wrap;
  text-align: justify;
  img {
    height: 2rem;
    margin-right: .5rem;
  }
  .info-bar-action-btn {
    width: 100%
  }
}
</style>
