<template>
  <v-navigation-drawer v-model="renderedShowInfo" absolute stateless height="100vh" width="500" class="navigation-info-bar">
    <v-row no-gutters justify="end">
      <v-btn @click="unselectItem" elevation="1" tile small color="error">&#x2715;</v-btn>
    </v-row>
    <v-row>
      <slot></slot>
    </v-row>
  </v-navigation-drawer>
</template>

<script>

export default {
  props: ['showInfo', 'unselectItem', 'selectedItemMetadata', 'setShowInfo'],
  computed: {
    renderedShowInfo: {
      get () {
        return this.showInfo
      },
      set (value) {
        this.setShowInfo(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'scssVariables';

.navigation-info-bar {
  z-index: 1000;
  background-color: #19222c !important;
}
</style>
