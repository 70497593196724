import * as Cesium from 'cesium/Cesium'

export const videoSnap = false
export const VIDEO_FLY_TIME = videoSnap ? 20000 : 1000
export const VIDEO_MODE_IMAGE_FOCUS = videoSnap ? 35000 : 3000
export const MIN_DETAIL_LEVEL = videoSnap ? 10 : 8
export const MAX_DETAIL_LEVEL = videoSnap ? 18 : 16
export const SCREEN_SPACE_ERROR = videoSnap ? 1 : 3
export const VIDEO_PLAY_MODE_MULTIPLIER = videoSnap ? 1 : 40

export const renderPx = (num) => {
  return `${num}px`
}

export const clamp = (value, min, max) => {
  return Math.max(min, Math.min(value, max))
}

export const setGeojsonPointHeight = (jsonFeature, height) => {
  jsonFeature.geometry.coordinates[2] = height
}

export const getGeojsonPointCoordinates = (jsonFeature) => {
  return jsonFeature.geometry.coordinates
}

/**
 * Traduit les coordonnées sphériques d'un point sous format json en coordonnées cartésiennes
 */
export const geojsonCoordinates2Cartesian3 = (jsonCoordinate, zElevation) => {
  return Cesium.Cartesian3.fromDegrees(jsonCoordinate[0], jsonCoordinate[1], jsonCoordinate[2] + (zElevation || 0))
}

/**
 * Traduit les coordonnées sphériques d'un point sous format json en coordonnées cartésiennes
 */
export const geojsonCoordinates2Cartesian2 = (jsonCoordinate) => {
  return Cesium.Cartesian3.fromDegrees(jsonCoordinate[0], jsonCoordinate[1])
}

/**
 * Calculer la distance entre deux points de l'itinéraire sous format JSON
 */
export const computeDistance = (jsonCoordinate1, jsonCoordinate2, flattened) => {
  const cloneCoord1 = [...jsonCoordinate1]
  const cloneCoord2 = [...jsonCoordinate2]

  if (flattened) {
    cloneCoord1[2] = 0.0
    cloneCoord2[2] = 0.0
  }

  const pos1 = geojsonCoordinates2Cartesian3(cloneCoord1)
  const pos2 = geojsonCoordinates2Cartesian3(cloneCoord2)
  return Cesium.Cartesian3.distance(pos1, pos2)
}

export const renderAltitude = (altitude) => {
  return `${altitude.toFixed(0)} m`
}

export const renderDistance = (distance) => {
  if (distance === 0) {
    return '0 km'
  }
  return `${(distance / 1000).toFixed(2)} km`
}

export const cameraInitialPosition = [7.58, 46.152, 12000]

export const sleep = (time) => {
  return new Promise((resolve, reject) => setTimeout(resolve, time))
}

export const setCameraView = (viewer, cartesianPosition, orientation, flyTo, duration) => {
  const args = {
    destination: cartesianPosition,
    orientation: orientation
  }
  if (duration) args.duration = Math.round(duration / 1000)
  const camera = viewer.camera

  if (flyTo) {
    camera.flyTo(args)
  } else {
    camera.setView(args)
  }
}

export const resetCamera = (viewer, flyTo, duration) => {
  setCameraView(
    viewer,
    Cesium.Cartesian3.fromDegrees(cameraInitialPosition[0], cameraInitialPosition[1], cameraInitialPosition[2]),
    {
      heading: Cesium.Math.toRadians(230.0),
      pitch: Cesium.Math.toRadians(-55.0),
      roll: 0
    },
    flyTo,
    duration
  )
}

export const getExtension = (fileName) => {
  return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName
}

export const getStepFromClockCurrentTime = (clock) => {
  const currentTime = clock.currentTime
  const start = clock.startTime
  return Cesium.JulianDate.secondsDifference(currentTime, start)
}
