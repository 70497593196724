<template>
  <span/>
</template>

<script>
import * as Cesium from 'cesium/Cesium'
import { ACTION_SET_STEP, PLAYER_STORE_NAME, PLAYER_IS_PLAYING_GETTER, PLAYER_STEP_GETTER, PLAYER_GET_TIMELINE_METADATA, VIDEO_PLAY_MODE, PLAYER_PLAY_MODE_GETTER, TRACKING_PLAY_MODE } from '@/store'
import * as Utils from '@/Utils'
import { mapGetters } from 'vuex'
import { viewerInstance as viewer } from '@/store/modules/viewer.js'

const GLOBAL_PLAY_MODE_CLOCK_MULTIPLIER = 100
const TRACKING_PLAY_MODE_CLOCK_MULTIPLIER = 40

export default {
  mounted () {
    // Make sure viewer is at the desired time.
    viewer.clock.startTime = this.start.clone()
    viewer.clock.stopTime = this.stop.clone()
    viewer.clock.currentTime = this.start.clone()
    // viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP // Loop at the end
    viewer.clock.multiplier = GLOBAL_PLAY_MODE_CLOCK_MULTIPLIER
    viewer.clock.onTick.addEventListener(() => {
      if (this.playing) {
      // Positionner le step correspondant dans le store
        let step = Utils.getStepFromClockCurrentTime(viewer.clock)
        step = step / this.totalDuration
        step = Utils.clamp(Math.ceil(step * this.maxStep), 0, this.maxStep)
        this.$store.dispatch(ACTION_SET_STEP, step)
      }
    })

    // Set timeline to simulation bounds
    if (viewer.timeline) viewer.timeline.zoomTo(this.start, this.stop)
  },
  computed: {
    ...mapGetters(PLAYER_STORE_NAME, {
      playing: PLAYER_IS_PLAYING_GETTER,
      getStep: PLAYER_STEP_GETTER,
      timelineMetadata: PLAYER_GET_TIMELINE_METADATA,
      playMode: PLAYER_PLAY_MODE_GETTER
    }),
    totalDuration () {
      return Cesium.JulianDate.secondsDifference(this.stop, this.start)
    }
  },
  watch: {
    playing (isPlaying) {
      viewer.clock.shouldAnimate = isPlaying
    },
    getStep (stepIndex) {
      const currentTime = viewer.clock.currentTime
      Cesium.JulianDate.addSeconds(this.start, stepIndex, currentTime)
    },
    playMode (value) {
      if (value === TRACKING_PLAY_MODE) {
        viewer.clock.multiplier = TRACKING_PLAY_MODE_CLOCK_MULTIPLIER
      } else if (value === VIDEO_PLAY_MODE) {
        viewer.clock.multiplier = Utils.VIDEO_PLAY_MODE_MULTIPLIER
      } else {
        viewer.clock.multiplier = GLOBAL_PLAY_MODE_CLOCK_MULTIPLIER
      }
    }
  },
  props: ['maxStep', 'start', 'stop']
}
</script>

<style>

</style>
