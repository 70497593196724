import Vue from 'vue'
import Vuex from 'vuex'
import player from './modules/player'
import itinerary from './modules/itinerary'
import informations from './modules/informations'
import viewer from './modules/viewer'
import access from './modules/access'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    player,
    itinerary,
    informations,
    viewer,
    access
  }
})

export * from './modules/player'
export * from './modules/itinerary'
export * from './modules/informations'
export * from './modules/viewer'
export * from './modules/access'
