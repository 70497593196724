import { renderDistance, renderAltitude } from '../Utils'
import coversMetaData from '@/data/parcours/metadata_couverture.json'

export default class ItineraryData {
  constructor (data) {
    this.alts = data.alts
    this.dist = data.dist
  }

  static createData (dist, alts) {
    return new ItineraryData({ alts: alts, dist: dist })
  }

  setCover (cover) {
    this.cover = cover
  }

  static getColorFromCover (cover) {
    const coverMetadata = coversMetaData[cover.type]
    return coverMetadata ? coverMetadata.color : 'red'
  }

  getCoverColor () {
    return this.getCover() ? ItineraryData.getColorFromCover(this.getCover()) : null
  }

  getCover () {
    return this.cover
  }

  getAltitude () {
    return this.alts
  }

  getDistance () {
    return this.dist
  }

  getFormattedAltitude () {
    return renderAltitude(this.alts)
  }

  getFormattedDistance () {
    return renderDistance(this.dist)
  }
}
