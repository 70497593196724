<template>
  <span/>
</template>

<script>
import * as Cesium from 'cesium/Cesium'
import { geojsonCoordinates2Cartesian2, geojsonCoordinates2Cartesian3 } from '@/Utils'
import { viewerInstance as viewer } from '@/store/modules/viewer.js'

export default {
  props: ['accessData'],
  data () {
    return {
      line: null
    }
  },
  mounted () {
    const dataCoordinates = this.accessData.getCoordinates()
    const departureCoordinates = dataCoordinates[0]
    let positions, departurePosition
    if (dataCoordinates[0].length === 3) {
      positions = Cesium.Cartesian3.fromDegreesArrayHeights(dataCoordinates.flat())
      departurePosition = geojsonCoordinates2Cartesian3(departureCoordinates)
    } else {
      positions = Cesium.Cartesian3.fromDegreesArray(dataCoordinates.flat())
      departurePosition = geojsonCoordinates2Cartesian2(departureCoordinates)
    }

    this.line = viewer.entities.add({
      polyline: {
        positions: positions,
        width: 4,
        material: Cesium.Color.DEEPSKYBLUE,
        clampToGround: true
      }
    })

    this.departure = viewer.entities.add({
      position: departurePosition,
      billboard: {
        image: this.accessData.getImageTransport(),
        scale: 0.5,
        verticalOrigin: Cesium.VerticalOrigin.CENTER,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 10000,
        pixelOffset: new Cesium.Cartesian3(0, -25)
      }
    })
  },
  destroyed () {
    viewer.entities.remove(this.line)
    viewer.entities.remove(this.departure)
  }
}
</script>

<style>

</style>
