<template>
  <v-list-item class="py-3 itinerary-infos">
    <v-row no-gutters align="center">
      <v-col cols="8">
      <v-list-item-title>
        {{itinerary.category}}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{itinerary.distance}} km<br>Heure de départ : {{itinerary.departureTime}}
      </v-list-item-subtitle>
      </v-col>
      <v-col cols="4">
        <v-btn :href="itinerary.gpxFileName()" class="mr-3" icon outlined elevation="2" plain>
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn :to="{ path: '/', query: { itinerary_key: itinerary.dataKey }}" icon outlined elevation="2" plain>
          <span class="visualize-span"></span> <v-icon>mdi-eye</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>

<script>
import Itinerary from '@/Classes/Itinerary'

export default {
  computed: {
    itineraryColor () {
      return Itinerary.getHexColor(this.itinerary)
    }
  },
  props: ['itinerary']
}
</script>

<style lang='scss'>
.itinerary-infos {
  .itinerary-color {
    width: 30px;
    height: 10px;
    border: 1px solid rgba(0,0,0,0);
    border-radius: 5px;
  }
}
</style>
