<template>
  <span class="timeline-container">
    <div @mousemove="lookAtMousePosition" @click="goToMousePosition" @mouseenter="mouseEnter" @mouseleave="mouseLeave" id='timeline'>
    </div>
    <MetaTimeline :borderMargins="borderMargins"/>
  </span>
</template>

<script>
import * as d3 from 'd3'
import Timeline from '../../../Classes/Timeline'
import MetaTimeline from './MetaTimeline'
import { mapGetters } from 'vuex'
import { ITINERARY_STORE_NAME, ITINERARY_PROFILE_GETTER, PLAYER_HOVER_STEP_GETTER, PLAYER_STORE_NAME, PLAYER_STEP_GETTER, ACTION_SET_PLAYER_TIMELINE_METADATA, ACTION_GO_TO_STEP, ITINERARY_GETTER_COVERS } from '@/store'
import { MARGIN_CHART } from 'scssJSVariables'

// Margin rate of y axis domain
const Y_MIN_DOMAIN = 0.3
const TIMELINE_ID = '#timeline'
const borderMargins = { top: 10, right: 10, bottom: 60, left: MARGIN_CHART }

export default {
  name: 'Timeline',
  data () {
    return {
      timeline: null,
      borderMargins: borderMargins
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initialize()
      window.addEventListener('resize', this.initialize)
    })
  },
  methods: {
    goToMousePosition: function (event) {
      this.$store.dispatch(ACTION_GO_TO_STEP, this.timeline.getStepFromChartPosition(event.pageX))
    },
    lookAtMousePosition: function (event) {
      this.timeline.lookAtMousePosition(event.pageX, event.pageY)
    },
    mouseEnter: function () {
      this.timeline.showUserPlayLine()
    },
    mouseLeave: function () {
      this.timeline.hideUserPlayLine()
    },
    smallScreen () {
      const screenSize = this.$vuetify.breakpoint.name
      return screenSize === 'xs' || screenSize === 'sm'
    },
    initialize () {
      this.timeline = new Timeline(TIMELINE_ID, d3, this.data, Y_MIN_DOMAIN, borderMargins, this.coverData)
      const timeLineMetaData = this.timeline.initialize(this.smallScreen)
      this.$store.dispatch(ACTION_SET_PLAYER_TIMELINE_METADATA, timeLineMetaData) // FIXME : UPDATE IF WINDOW SIZE CHANGES
    }
  },
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      data: ITINERARY_PROFILE_GETTER,
      coverData: ITINERARY_GETTER_COVERS
    }),
    ...mapGetters(PLAYER_STORE_NAME, {
      step: PLAYER_STEP_GETTER,
      hoverStep: PLAYER_HOVER_STEP_GETTER
    }),
    devMode () {
      return process.env.NODE_ENV !== 'production'
    }
  },
  watch: {
    step: function (value) {
      this.timeline.handleAutomaticPlayPosition(value)
    },
    hoverStep: function (value) {
      this.timeline.setHoverStep(value)
    },
    data (newData, oldData) {
      if (this.timeline) {
        this.initialize()
      }
    }
  },
  components: { MetaTimeline: MetaTimeline }
}

</script>

<style lang='scss'>

@import 'scssVariables';

.timeline-container {

}

#timeline {
  position: relative;
  width: 100%;
  height: 150px;
  cursor: pointer;
  color: white;
}

.axis path,
.axis line {
  fill: none;
  stroke: white;
  shape-rendering: crispEdges;
}

.vertical-line {
  z-index: 0;
}

.area {
  stroke: #bdc3c7;
  z-index: 100;
  stroke-width: 1px;
  fill: rgba(0,0,0,0)
}

line.poi {
  stroke: grey;
  shape-rendering: crispEdges;
}
line.poi2 {
  stroke: white;
  shape-rendering: crispEdges;
}

.vertical-line-section {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .symbol {
    font-size: 1.5rem;
    display: inline-block;
    width: 2rem;
    &.alt {
      font-size: 1.8rem;
    }
  }
}

</style>
