<template>
  <v-menu :close-on-content-click="false" transition="slide-x-reverse-transition" left offset-x :nudge-left="nudgeLeft" rounded="x-lg">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            outlined
            class="mt-2"
            x-large
            @click="showMenu = true"
          >
            <v-icon>mdi-layers</v-icon>
          </v-btn>
        </template>
        <span>Fonds carte / couches</span>
      </v-tooltip>
    </template>
    <v-radio-group class="select-layer" label="Fonds de carte" v-model="selectedLayer">
      <v-radio
        @change="handleChangeLayer(layer)"
        v-for="(layer) in layers"
        :key="layer.key"
        :label="layer.label"
        :value="layer"/>
    </v-radio-group>
    <!-- <v-col col="24" class="mt-0 pt-0">
      <legend class="v-label theme--light">Couches</legend>
      <v-checkbox class="mt-1 pt-1" label="Accès" v-model="displayAccesses"/>
      <AccessSelector v-show="displayAccesses"/>
    </v-col> -->
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { VIEWER_STORE_NAME, VIEWER_ACTION_SELECT_LAYER, VIEWER_GET_AVAILABLE_LAYERS, VIEWER_GET_SELECTED_LAYER, initialSelectedLayer } from '@/store'

export default {
  data () {
    return {
      selectedLayer: initialSelectedLayer,
      showMenu: initialSelectedLayer,
      displayAccesses: false,
      nudgeLeft: 20
    }
  },
  computed: {
    ...mapGetters(VIEWER_STORE_NAME, {
      layers: VIEWER_GET_AVAILABLE_LAYERS,
      selectedLayerStore: VIEWER_GET_SELECTED_LAYER
    })
  },
  methods: {
    handleChangeLayer (layer) {
      this.selectedLayer = layer
      this.showMenu = false
      this.$store.dispatch(VIEWER_ACTION_SELECT_LAYER, layer)
    }
  }
}
</script>

<style lang="scss">
@import 'scssVariables';

.v-menu__content {
background: $color-cloud;
}

legend.v-label {
  font-size: 14px;
}

.select-layer {

  .v-input--radio-group__input {
    padding: .6rem .75rem;
  }
}
</style>
