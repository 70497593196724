<template>
  <div id="navigation-control-container" class="d-flex flex-column">
    <CameraController class="mb-2"/>
    <template v-if="!videoMode">
      <ItinerarySelector/>
      <div id="close-menu-div"></div>
      <LayerSelector/>
      <template v-if="!!itinerary">
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="mt-2" dark icon outlined x-large :href="itineraryUrl">GPX</v-btn>
        </template>
        <span>Télécharger itinéraire visualisé</span>
        </v-tooltip>
      </template>
    </template>
  </div>
</template>

<script>
import LayerSelector from './LayerSelector'
import CameraController from './CameraController'
import { mapGetters } from 'vuex'
import { ITINERARY_STORE_NAME, ITINERARY_GETTER_VIEWER, ITINERARY_GETTER, PLAYER_STORE_NAME, PLAYER_PLAY_MODE_GETTER, VIDEO_PLAY_MODE } from '@/store'
import ItinerarySelector from './ItinerarySelector'

const gpxFileUrl = (itinerary) => {
  return `/${itinerary.name}.gpx`
}

export default {
  components: { LayerSelector: LayerSelector, ItinerarySelector: ItinerarySelector, CameraController: CameraController },
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      viewer: ITINERARY_GETTER_VIEWER,
      itinerary: ITINERARY_GETTER
    }),
    ...mapGetters(PLAYER_STORE_NAME, {
      playMode: PLAYER_PLAY_MODE_GETTER
    }),
    videoMode () {
      return this.playMode === VIDEO_PLAY_MODE
    },
    itineraryUrl () {
      return gpxFileUrl(this.itinerary)
    }
  }
}
</script>

<style lang="scss">
#navigation-control-container {
  position: absolute;
  z-index: 500;
  top: 10px;
  right: 10px;
  /* padding: 15px; */
  #close-menu-div {
    position: fixed;
    left: 0;
    top: 0;
  }
}
</style>
