import { videoSnap, clamp, setCameraView, resetCamera, sleep, VIDEO_FLY_TIME, VIDEO_MODE_IMAGE_FOCUS } from '@/Utils'
import { viewerInstance } from '@/store/modules/viewer.js'
import * as Cesium from 'cesium/Cesium'
import { INFORMATION_ACTION_SET_SELECTED_ITEM, INFORMATION_ACTION_UNSELECTED_ITEM } from '@/store'

export const PLAYER_STORE_NAME = 'player'
export const PLAYER_STEP_GETTER = 'getStep'
export const PLAYER_HOVER_STEP_GETTER = 'getHoverStep'
export const PLAYER_IS_PLAYING_GETTER = 'getIsPlaying'
export const PLAYER_PLAY_MODE_GETTER = 'getPlayMode'
export const PLAYER_GET_TIMELINE_METADATA = 'getTimeLineMetaData'
export const ACTION_INCREMENT_STEP = 'incrementStep'
export const ACTION_RESET_STEP = `${PLAYER_STORE_NAME}/resetStep`
export const ACTION_GO_TO_STEP = `${PLAYER_STORE_NAME}/goToStep`
export const ACTION_SET_PLAYER_TIMELINE_METADATA = `${PLAYER_STORE_NAME}/setTimelineMetadata`
export const ACTION_TOGGLE_PLAY = `${PLAYER_STORE_NAME}/togglePlay`
export const ACTION_REPLAY = `${PLAYER_STORE_NAME}/replay`
export const ACTION_SET_STEP = `${PLAYER_STORE_NAME}/setStep`
export const ACTION_SET_HOVER_STEP = `${PLAYER_STORE_NAME}/setHoverStep`
export const ACTION_TOGGLE_PLAY_MODE = `${PLAYER_STORE_NAME}/togglePlayMode`
export const TRACKING_PLAY_MODE = 'tracking'
export const GLOBAL_PLAY_MODE = 'global'
export const VIDEO_PLAY_MODE = 'video'
export const ACTION_SET_PLAY_MODE = `${PLAYER_STORE_NAME}/setPlayMode`
export const ACTION_AUTO_FOCUS_POI = `${PLAYER_STORE_NAME}/autoFocusPoi`
export const ACTION_HANDLE_VIEW_VIEWER_IMAGE_LOADED = `${PLAYER_STORE_NAME}/handleViewViewerImageLoaded`

let tempCameraPosition
let tempCameraOrientation

const state = () => ({
  step: 0,
  hoverStep: null,
  width: null,
  xAxis: null,
  nbData: null,
  playing: false,
  marginLeft: 0,
  playMode: GLOBAL_PLAY_MODE
})

const getters = {
  getStep: (state, getters, rootState) => {
    return state.step
  },
  getIsPlaying: (state, getters, rootState) => {
    return state.playing
  },
  getTimeLineMetaData: (state) => {
    return {
      width: state.width,
      nbData: state.nbData,
      xAxis: state.xAxis,
      marginLeft: state.marginLeft
    }
  },
  getHoverStep: (state) => {
    return state.hoverStep
  },
  getPlayMode: (state) => {
    return state.playMode
  }
}

const mutations = {
  setStep (state, { step, itinerary }) {
    const newValue = clamp(step, 0, state.nbData - 1)
    if (newValue === state.nbData - 1) state.playing = false
    state.step = newValue
    // if (process.env.NODE_ENV === 'development') {
    //   console.debug('Changement de step', newValue, 'position curseur', itinerary.features[0].geometry.coordinates[0][newValue])
    // }
    return state.step
  },
  setHoverStep (state, value) {
    state.hoverStep = value
  },
  setTimelineMetadata (state, metadata) {
    state.xAxis = metadata.x
    state.width = metadata.width
    state.nbData = metadata.nbData
    state.marginLeft = metadata.marginLeft
  },
  setPlaying (state, { playing }) {
    state.playing = playing
  },
  setPlayMode (state, value) {
    state.playMode = value
  }
}

const actions = {
  incrementStep ({ commit, rootState, state, dispatch }) {
    const xAxis = state.xAxis
    if (xAxis == null) {
      console.error(`${PLAYER_STORE_NAME} - incrementStep : xAxisIsNull `)
      return -1
    } else {
      if (state.step < state.nbData - 1) {
        dispatch('goToStep', state.step + 1)
      } else {
        // Stop the player
        commit('setPlaying', { playing: false })
        // Reset the camera to have a global view
        resetCamera(viewerInstance, true)
      }
    }
    // Réinitialiser caméra lorsqu'arrivé à la dernière étape
  },
  goToStep ({ commit, rootState, state }, step) {
    commit('setStep', { step: step, itinerary: rootState.itinerary.currentItinerary })
    // Reset the camera to have a global view
    if (state.step >= state.nbData - 1) {
      setTimeout(() => {
        resetCamera(viewerInstance, true, videoSnap ? VIDEO_FLY_TIME : null)
      })
    }
  },
  resetStep ({ dispatch, commit }) {
    dispatch('goToStep', 0)
    commit('setPlaying', { playing: false })
  },
  setStep ({ dispatch }, stepValue) {
    dispatch('goToStep', stepValue)
  },
  setTimelineMetadata ({ commit }, metadata) {
    commit('setTimelineMetadata', metadata)
  },
  togglePlay ({ commit, state, dispatch }) {
    commit('setPlaying', { playing: !state.playing })
  },
  replay ({ commit, state, dispatch }) {
    commit('setPlaying', { playing: false })
    dispatch(ACTION_RESET_STEP)
  },
  setHoverStep ({ commit }, value) {
    commit('setHoverStep', value)
  },
  setPlayMode ({ commit }, value) {
    commit('setPlayMode', value)
  },
  togglePlayMode ({ commit, state }, value) {
    state.playMode = state.playMode === TRACKING_PLAY_MODE ? GLOBAL_PLAY_MODE : TRACKING_PLAY_MODE
  },
  async autoFocusPoi ({ dispatch, commit, state }, poi) {
    console.debug('Focus sur le POI', poi.name)
    dispatch('togglePlay')
    // Focus le poi
    // // Récuperer les paramètres de la caméra avant focus
    const camera = viewerInstance.camera
    tempCameraPosition = { ...camera.position }
    tempCameraOrientation = {
      heading: camera.heading,
      pitch: camera.pitch,
      roll: camera.roll
    }

    // // Positionner la caméra sur le vecteur camera-poi avec un offset
    let newCameraPosition = new Cesium.Cartesian3()
    const cameraToPoi = new Cesium.Cartesian3()
    Cesium.Cartesian3.subtract(poi.cartesianCoordinates, tempCameraPosition, cameraToPoi)
    Cesium.Cartesian3.normalize(cameraToPoi, cameraToPoi)
    Cesium.Cartesian3.multiplyByScalar(cameraToPoi, 2000, cameraToPoi)
    newCameraPosition = Cesium.Cartesian3.add(tempCameraPosition, cameraToPoi, newCameraPosition)
    setCameraView(viewerInstance, newCameraPosition, tempCameraOrientation, true, VIDEO_FLY_TIME)
    await sleep(VIDEO_FLY_TIME)
    dispatch(INFORMATION_ACTION_SET_SELECTED_ITEM, poi, { root: true })
  },
  async handleViewViewerImageLoaded ({ dispatch, commit, state }) {
    if (state.playMode === VIDEO_PLAY_MODE) {
      await sleep(VIDEO_MODE_IMAGE_FOCUS)
      // // Restaurer la position de la caméra
      dispatch(INFORMATION_ACTION_UNSELECTED_ITEM, {}, { root: true })
      setCameraView(viewerInstance, tempCameraPosition, tempCameraOrientation, true, VIDEO_FLY_TIME)
      await sleep(VIDEO_FLY_TIME)
      dispatch('togglePlay')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
