<template>
  <span>
    <InfoBar v-if="infoBarMode" :showInfo="showInfo" :unselectItem="unselectItem" :setShowInfo="setShowInfo">
      <InfoContent v-if="selectedItemMetadata" :selectedItemMetadata="selectedItemMetadata" :correspondingAccessLink="correspondingAccessLink"/>
    </InfoBar>
    <InfoModal v-else :showInfo="showInfo" :unselectItem="unselectItem" :setShowInfo="setShowInfo">
      <InfoContent v-if="selectedItemMetadata" :videoMode="true" :selectedItemMetadata="selectedItemMetadata" :correspondingAccessLink="correspondingAccessLink"/>
    </InfoModal>
  </span>
</template>

<script>
// import * as Cesium from 'cesium/Cesium'
import { resetCamera } from '@/Utils'
import { mapGetters } from 'vuex'
import { ITINERARY_STORE_NAME, INFORMATION_STORE_NAME, ACCESS_STORE_NAME, GETTER_ACCESSES_DATA, INFORMATION_GET_SELECTED_ITEM_GETTER, INFORMATION_ACTION_UNSELECTED_ITEM, ITINERARY_GETTER_VIEWER, ITINERARY_GETTER_INFOS, PLAYER_STORE_NAME, PLAYER_PLAY_MODE_GETTER, VIDEO_PLAY_MODE } from '@/store'
import { viewerInstance as viewer } from '@/store/modules/viewer.js'
import InfoBar from './InfoBar'
import InfoModal from './InfoModal'
import InfoContent from './InfoContent'

export default {
  components: { InfoBar: InfoBar, InfoModal: InfoModal, InfoContent: InfoContent },
  data () {
    return {
      showInfo: false
    }
  },
  mounted () {
    this.initializeInfo()
  },
  methods: {
    setShowInfo (value) {
      this.showInfo = value
    },
    initializeInfo () {
      this.showInfo = !!this.selectedItemMetadata
    },
    unselectItem () {
      this.$store.dispatch(INFORMATION_ACTION_UNSELECTED_ITEM)
      this.resetCamera(viewer, true)
    },
    viewItem (item) {
      viewer.flyTo(null)
      viewer.flyTo(item.cesiumEntity, {
        offset: {
          heading: -3.14 / 4,
          pitch: -3.14 / 10,
          range: 700
        }
      })
    },
    resetCamera: resetCamera
  },
  computed: {
    ...mapGetters(INFORMATION_STORE_NAME, {
      selectedItemMetadata: INFORMATION_GET_SELECTED_ITEM_GETTER
    }),
    ...mapGetters(ITINERARY_STORE_NAME, {
      viewer: ITINERARY_GETTER_VIEWER,
      getItineraryInfos: ITINERARY_GETTER_INFOS
    }),
    ...mapGetters(ACCESS_STORE_NAME, {
      accesses: GETTER_ACCESSES_DATA
    }),
    ...mapGetters(PLAYER_STORE_NAME, {
      playMode: PLAYER_PLAY_MODE_GETTER
    }),
    infoBarMode () {
      return this.playMode !== VIDEO_PLAY_MODE
    },
    correspondingAccessLink () {
      const accessKey = this.selectedItemMetadata.accessKey
      if (!this.selectedItemMetadata.accessKey) return null
      const correspondingAccess = this.accesses.find(access => access.getKey() === accessKey)
      if (!correspondingAccess) return null
      return correspondingAccess.getLink()
    }
  },
  watch: {
    selectedItemMetadata (value) {
      this.initializeInfo()
    },
    getItineraryInfos (newInfos, oldInfos) {
      if (newInfos !== oldInfos) {
        this.unselectItem()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.navigation-info-bar {
  z-index: 1000;
  background-color: #19222c !important;
}

</style>
