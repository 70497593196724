import masterItinerary from '@/data/parcours/master_smooth.json'
import itinerary77 from '@/data/parcours/78km_smooth.json'
import itinerary70 from '@/data/parcours/70km_smooth.json'
import masterItineraryCover from '@/data/parcours/master_couverture.json'
import itinerary77Cover from '@/data/parcours/78km_couverture.json'
import itinerary70Cover from '@/data/parcours/70km_couverture.json'
import * as Cesium from 'cesium/Cesium'

export const COVER_TYPE_CHEMIN = 'Chemin'
export const COVER_TYPE_FOREST_CHEMIN = 'Route forestière'
export const COVER_TYPE_ASPHALT = 'Route goudronnée'

export default class Itinerary {
  constructor (id, category, distance, departureTime) {
    this.id = id
    this.category = category
    this.distance = distance
    this.departureTime = departureTime
    this.dataKey = id
  }

  static getCorrespondingData (itinerary) {
    switch (itinerary.dataKey) {
      case MASTER_MANM50:
      case MASTER_WOMAN:
        return masterItinerary
      case ELITE_WOMAN:
        return itinerary70
      case ELITE_MAN:
      case MASTER_MAN:
        return itinerary77
    }
  }

  gpxFileName () {
    return `${Itinerary.getCorrespondingData(this).name}.gpx`
  }

  static getColor (itinerary) {
    switch (itinerary.dataKey) {
      case MASTER_MANM50:
      case MASTER_WOMAN:
        return Cesium.Color.SANDYBROWN
      case ELITE_WOMAN:
        return Cesium.Color.SANDYBROWN
      case ELITE_MAN:
      case MASTER_MAN:
        return Cesium.Color.SANDYBROWN
      default:
        console.error(`Itinerary with key ${itinerary.dataKey} does not have a color`)
    }
  }

  static getHexColor (itinerary) {
    return Itinerary.getColor(itinerary).toCssHexString()
  }

  static initializeCover (coverMaxDistance) {
    return {
      type: null,
      length: 0,
      absoluteDistance: 0,
      maxDistance: coverMaxDistance
    }
  }

  static formatCover (dataCover) {
    const covers = []
    let currentCover = null
    const features = dataCover.features
    // eslint-disable-next-line dot-notation
    const coverMaxDistance = features.length ? parseFloat(features[features.length - 1].properties['sumLength']) : 0
    for (let i = 0; i < features.length; i++) {
      const feature = features[i]
      const coverProperties = feature.properties
      const coverType = coverProperties.type
      // eslint-disable-next-line dot-notation
      const coverLength = parseFloat(coverProperties['length'])
      // eslint-disable-next-line dot-notation
      const absoluteDistance = parseFloat(coverProperties['sumLength'])

      if (currentCover === null) {
        currentCover = Itinerary.initializeCover(coverMaxDistance)
        currentCover.type = coverType
      }

      let smallPortion = false
      if (i < features.length - 1) {
        const nextFeatureChangeType = features[i].properties.type !== coverType
        smallPortion = coverLength < 100 && !nextFeatureChangeType
      }

      if (coverType === currentCover.type || smallPortion) {
        currentCover.length = currentCover.length + coverLength
        currentCover.absoluteDistance = absoluteDistance
      } else {
        covers.push(currentCover)
        currentCover = Itinerary.initializeCover(coverMaxDistance)
        currentCover.length = coverLength
        currentCover.type = coverType
        currentCover.absoluteDistance = absoluteDistance
      }
      if (i === features.length - 1) covers.push(currentCover)
    }
    return covers
  }

  static getCover (itinineraryKey) {
    let dataCover
    switch (itinineraryKey) {
      case MASTER_MANM50:
      case MASTER_WOMAN:
        dataCover = masterItineraryCover
        break
      case ELITE_WOMAN:
        dataCover = itinerary70Cover
        break
      case ELITE_MAN:
      case MASTER_MAN:
        dataCover = itinerary77Cover
        break
    }
    return this.formatCover(dataCover)
  }
}

export const ELITE_WOMAN = 'ELITE_WOMAN'
export const MASTER_WOMAN = 'MASTER_WOMAN'
export const MASTER_MANM50 = 'MASTER_MANM50'
export const ELITE_MAN = 'ELITE_MAN'
export const MASTER_MAN = 'MASTER_MAN'

export const MASTER_ITINERARY = 'MASTER_ITINERARY'
export const ITINERARY70km = 'ITINERARY70km'
export const ITINERARY78km = 'ITINERARY78km'

export const itineraries = [
  new Itinerary(ELITE_WOMAN, 'Dames élite', '70', '9h00', ITINERARY70km),
  new Itinerary(MASTER_WOMAN, 'Dames Master', '65', '9h05', MASTER_ITINERARY),
  new Itinerary(MASTER_MANM50, 'Homme Master >=M50', '65', '9h10', MASTER_ITINERARY),
  new Itinerary(ELITE_MAN, 'Hommes élite', '78', '9h45', ITINERARY78km),
  new Itinerary(MASTER_MAN, 'Hommes Master', '78', '9h50', ITINERARY78km)
]
