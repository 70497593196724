<template>
  <span @click="click" v-bind:style="style" class="timeline-poi">
    {{poi.label}}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { ITINERARY_MAX_DIST_GETTER, ITINERARY_STORE_NAME, PLAYER_STORE_NAME, PLAYER_GET_TIMELINE_METADATA, INFORMATION_ACTION_SET_SELECTED_ITEM } from '@/store'
import { renderPx } from '@/Utils'

export default {
  props: ['poi', 'offsetLabel'],
  methods: {
    click () {
      this.$store.dispatch(INFORMATION_ACTION_SET_SELECTED_ITEM, this.poi)
    }
  },
  computed: {
    ...mapGetters(ITINERARY_STORE_NAME, {
      itineraryMaxDistance: ITINERARY_MAX_DIST_GETTER
    }),
    ...mapGetters(PLAYER_STORE_NAME, {
      timelineMetadata: PLAYER_GET_TIMELINE_METADATA
    }),
    style () {
      return {
        left: renderPx(Math.round((this.poi.distance / this.itineraryMaxDistance) * this.timelineMetadata.width))
      }
    }
  }
}
</script>

<style lang="scss">
@import 'scssVariables';

$size-poi-button: 1.9rem;

.timeline-poi {
  position: absolute;
  font-weight: bold;
  text-align: center;
  align-self: center;
  transform: translateX(-50%);
  border-radius: 50%;
  background: rgb(236, 240, 241);
  color: rgb(44, 62, 80);
  width: $size-poi-button;
  height: $size-poi-button;
  padding: .1rem .2rem;
  transition: all .1s ease-in-out;
  box-shadow: $box-shadow-2;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  &:hover {
    background: rgb(189, 195, 199);
    cursor: pointer;
  }
  &:active {
    background: rgb(127, 140, 141);
  }
}
</style>
